





import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Idle, DocumentInterruptSource, SimpleExpiry, StorageInterruptSource } from '@ng-idle/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable, timer } from 'rxjs';
import { OAuthAuthenticationService } from './core/authentication/google.authentication.service';

import { environment } from '../environments/environment';
import { Logger } from './core/logger.service';
import { I18nService } from './core/i18n.service';
import { NavUtilityService } from './shared/services/nav-utility.service';
import { CustomHeaderComponent } from './core/shell/custom-header/custom-header.component';
import { AuthenticationService } from './core/authentication/authentication.service';
import { SharedApiUtilityService } from './shared/services/shared-api-utility.service';
import { takeWhile, take } from 'rxjs/operators';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {


  sessionId = '';
  private alive = true;
  private logoutRoute = environment.serverUrl + '/logout' + '?session_id='

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private idle: Idle,
    private navUtilityService: NavUtilityService,
    private googleAuthenticationService: OAuthAuthenticationService,
    private sharedApiUtilityService: SharedApiUtilityService,
    private authenticationService: AuthenticationService,
    private i18nService: I18nService) {
    this.sessionId = this.authenticationService.credentials
      ? this.authenticationService.credentials.sessionId
      : null;
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
    this.translateService.setDefaultLang(environment.defaultLanguage);
    if (!!this.navUtilityService.getIdleConfiguration) {
      const idleConfiguration = this.navUtilityService.getIdleConfiguration;
      // sets an idle timeout in seconds.
      this.idle.setIdle(idleConfiguration.IdleTimeInSeconds);
      // sets a timeout period in seconds. after Idle+Timeout seconds of inactivity, the user will be considered timed out.
      this.idle.setTimeout(idleConfiguration.TimeoutInSeconds);
      let idleInterrupts = [new DocumentInterruptSource('click DOMMouseScroll touchmove scroll'),
      new StorageInterruptSource()]
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      this.idle.setInterrupts(idleInterrupts);

      this.idle.onIdleEnd.subscribe(() => {
        this.navUtilityService.timoutWarningData.next(null)
      });
      this.idle.onTimeout.subscribe(() => {
        this.navUtilityService.setIsLoggedOut(true);
              this.sessionId = this.navUtilityService.getSessionId;
              this.sharedApiUtilityService.logOut(this.sessionId)
                .pipe(takeWhile(() => this.alive))
                .subscribe((data: any) => {
                  status = data.status
                  if (!!status && status.toLowerCase() === 'success') {
                    this.authenticationService.updateCredentials(null);
                    this.navUtilityService.setIsLoggedOut(true);
                    this.navUtilityService.isLoginSubject.next(false);
                    this.navUtilityService.countDownMessageCompleted.next(true);
                    this.navUtilityService.appLogOutSessionTimeout.next(true);
                    this.router.navigate(['/logout']);
                  }  else {
                    this.navUtilityService.appLogOutSessionTimeout.next(true);
                    this.router.navigate(['/logout']);
                    this.navUtilityService.isLoginSubject.next(false);
                    this.authenticationService.updateCredentials(null);
                    this.navUtilityService.setIsLoggedOut(true);
                  }
                });

        /* this.googleAuthenticationService.removeAuthToken().subscribe(() => {
         });*/

      });
      this.idle.onIdleStart.subscribe(() => {

      })
      this.idle.onTimeoutWarning.subscribe((countdown: any) => {
        this.sessionId = this.navUtilityService.getSessionId;
      });
      this.idle.watch();
    }
  }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    // Setup translations


    // Change page title on navigation or language change, based on route data
    /*const onNavigationEnd = this.router.events.filter(event => event instanceof NavigationEnd);
    Observable.merge(this.translateService.onLangChange, onNavigationEnd)
      .map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter(route => route.outlet === 'primary')
      .mergeMap(route => route.data)
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });*/
  }
  ngOnDestroy(): void {
  }
}
