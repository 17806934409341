import { Component, OnInit, OnDestroy } from '@angular/core';
import { HomeService } from './home.service';
import { Router } from '@angular/router';
import { CommonUtilityService } from '../shared/services/common-utility.service';
import { AuthenticationService } from '../core/authentication/authentication.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {

  isLoading = false;
  private alive = true; 

  constructor(private homeService: HomeService,
  private authenticationService: AuthenticationService,
  private router: Router,
  private commonUtilityService:CommonUtilityService) {
  }

  ngOnInit() {
    
  }
  
 
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.alive = false;
  }

}
