import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader/loader.component';
import { NavUtilityService } from './services/nav-utility.service';
import { SharedApiUtilityService } from './services/shared-api-utility.service';
import { CommonUtilityService } from './services/common-utility.service';
import { TranslationPipePipe } from '../shared/pipe/translation-pipe.pipe';
import { BooleanToCustomvaluePipe } from './pipe/boolean-to-customvalue.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LoaderComponent,
    TranslationPipePipe,
    BooleanToCustomvaluePipe
  ],
  providers: [
    NavUtilityService,
    SharedApiUtilityService,
    CommonUtilityService
  ],
  exports: [
    LoaderComponent,
    TranslationPipePipe,
    BooleanToCustomvaluePipe
  ],
})
export class SharedModule { }
