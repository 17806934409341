<footer>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="footerdiv" translate>
        <span translate>Footer.Title1</span>
        <span translate>{{ currentYear }}</span>
        <span translate>Footer.Title2</span>
      </div>
    </div>
  </div>
</footer>
