import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listToCommaseparated'
})
export class ListToCommaseparatedPipe implements PipeTransform {
  transform(value: any[], isSort?: any): any {
    let returnValue = '';
    if (value) {
      if (isSort) {
        value = value.sort( (a, b) => {
          return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      }
      value.forEach((element: any) => {
        returnValue = returnValue + element + ', ';
      });
      returnValue = returnValue.slice(0, returnValue.length - 2);
      return returnValue;
    } else {
      return returnValue;
    }
  }

}
