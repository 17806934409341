import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(public authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestURL: string = request.url;
    if (requestURL.indexOf("?") == -1) {
      requestURL = requestURL;
    } else {
      requestURL = requestURL;
    }
    
    request = request.clone({
      setHeaders: {
        Authorization: `${this.authenticationService.getAuthToken}`
      },
      url: requestURL
    });

    return next.handle(request);
  }
}