// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  version: '(dev_asa)',
  bucket: 'pg-us-n-app-pgid.appspot.com',
  serverUrl: 'https://backend-dot-pg-us-n-app-828517.appspot.com',
  webUrl: 'https://gcet-dev.pwc.com',
  clientId: '324514697164-0vfa91levo1r14upaof1pr2ak59jpkcb.apps.googleusercontent.com',
  apiKey: 'AIzaSyCtebVSgk9whCwcoiGz9dRwTFzQFL7wQjI',
  oAuthScope: 'https://www.googleapis.com/auth/drive+profile+email+https://www.googleapis.com/auth/drive.file+https://www.googleapis.com/auth/spreadsheets',
  oAuthScopeFileOwner:'https://www.googleapis.com/auth/drive+profile+email+https://www.googleapis.com/auth/admin.directory.user.readonly+https://www.googleapis.com/auth/drive.file+https://www.googleapis.com/auth/drive.metadata.readonly+https://www.googleapis.com/auth/spreadsheets',
  fileOwnerSigninPathName: 'fileowner',
  defaultLanguage: 'en-US',
  isLocal: true,
  idleConfiguration:{"IdleTimeInSeconds": 760,"TimeoutInSeconds": 120},
  supportedLanguages: [
    'en-US'
  ],
 };
