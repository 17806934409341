import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Oauth2Component } from './oauth2.component';

const routes: Routes = [
  { path: 'oauth2callback', component: Oauth2Component }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class Oauth2RoutingModule { }
