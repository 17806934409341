import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class NavUtilityService {
  appUserName: BehaviorSubject<any> = new BehaviorSubject<any>({});

  downloadComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  timoutWarningData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  isLoginSubject: BehaviorSubject<any> = new BehaviorSubject<boolean>(true);
  searchInitiated: BehaviorSubject<any> = new BehaviorSubject<boolean>(true);
  countDownMessageCompleted: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  menuButtonClicked: BehaviorSubject<any> = new BehaviorSubject<any>({ title: 'Search Calendar'});
  appLogOutSessionTimeout: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  
  
  private isLoggedOut = false;
  private applicationRoutes: any[] = [];
  private applicationRouteKey = 'sdmApi';  
  private userStateRes: any[] = [];
  private idleConfigurationKey = 'idleConfiguration';
  private showSearchInitiatedMessage = false;
  private sessionId = '';
  
  constructor() { }

  
  setIsLoggedOut(isLoggedOut: boolean) {
    this.isLoggedOut = isLoggedOut;
  }

  get getIsLoggedOut(): boolean {
    return this.isLoggedOut;

  }
  setSessionId(sessionId: string) {
    this.sessionId = sessionId;
  }

  get getSessionId(): string {
    return this.sessionId;

  }

  get getUserStateApi(): any {
    return this.userStateRes;
  }

  setUserStateApi(userStateRes: any) {
    this.userStateRes = userStateRes;
  }
 
  setApplicationRoutes(applicationRoutes: any[]) {
    this.applicationRoutes = applicationRoutes;
    sessionStorage.setItem(this.applicationRouteKey, JSON.stringify(this.applicationRoutes));
  }
  get getApplicationRoutes(): any[] {
    return !!this.applicationRoutes && this.applicationRoutes.length > 0 ?
      this.applicationRoutes :
      !!sessionStorage.getItem(this.applicationRouteKey) ?
        JSON.parse(sessionStorage.getItem(this.applicationRouteKey)) : [];
  }

  getApiRouteByKey(key: string): string {
    if (this.getApplicationRoutes && this.getApplicationRoutes.length > 0) {
      const apiRoute = this.getApplicationRoutes
        .filter((apiObj: any) => apiObj.api === key);
      return !!apiRoute && apiRoute.length > 0 ? apiRoute[0].url : null;
    }
    return null;
  }
  idleConfig: any;
  setIdleConfiguration(idleConfig: any) {
    this.idleConfig = idleConfig;
    sessionStorage.setItem(this.idleConfigurationKey, JSON.stringify(this.idleConfig));
  }
  get getIdleConfiguration(): any {
    return !!this.idleConfig ?
      this.idleConfig :
      !!sessionStorage.getItem(this.idleConfigurationKey) ?
        JSON.parse(sessionStorage.getItem(this.idleConfigurationKey)) : environment.idleConfiguration;
  }

  isLoggedIn(): Observable<boolean> {
		return this.isLoginSubject.asObservable();
  }
  
  
  get getShowSearchInitiatedMessage(): boolean {
    return this.showSearchInitiatedMessage;
  }

  setShowSearchInitiatedMessage(showSearchInitiatedMessage: boolean) {
    this.showSearchInitiatedMessage = showSearchInitiatedMessage;
  }
}

