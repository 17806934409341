import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'permissionTypeConversion'
})
export class PermissionTypeConversionPipe implements PipeTransform {

  transform(value: string): string {
    if (value === 'editors') {
      return 'Can Edit';
    } else if (value === 'commenter') {
      return 'Can Comment';
    } else {
      return 'Can Read';
    }
  }

}
