<div class="googleSearchCalendar-container">
  <p-panel class="no-header">
    <div class="row">
      <div class="col-md-12 float-right text-right">
        <button *ngIf="(searchHistoryList.length > 0) && !linkPresent" pButton type="button" class="btn btn-primary btn-md sdmButton " (click)="exportData()"
          label="{{'LogsAndReports.ExportIcon' | translationPipe}}"></button>
        <label style="float: right" *ngIf="(searchHistoryList.length > 0) &&  !!linkPresent" translate>
          LogsAndReports.Click<a [href]="link" target="_blank" class="hyperlinks" (click)="resetLink()" translate>LogsAndReports.Here</a>LogsAndReports.Exportlink
        </label>
        <button pButton type="button" class="btn btn-primary btn-md sdmButton addMarginleft" (click)="refreshSearchHistoryGrid()"
          label="{{'MySearchHistory.RefreshButton' | translationPipe}}"></button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 p-3">
        <p-table [value]="searchHistoryList" [rows]="selectedrowsPerPageOption" [columns]="searchHistoryGridColumn"
          [rowsPerPageOptions]="rowsPerPageOptions" [pageLinks]="4" [reorderableColumns]="false" [responsive]="true"
          [paginator]="true" [resizableColumns]="false" #grid [(first)]="first" styleClass=""> <!--added shared cal UI fix -true-->
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field" [hidden]="col.isHidden"
              [pSortableColumnDisabled]="col.field === 'AttachmentSummary'" [ngStyle]="col.style" [ngClass]="col.class">
                {{col.header}}
                <p-sortIcon [field]="col.field" *ngIf="col.field !== 'AttachmentSummary'"></p-sortIcon>
              </th>
              <th [ngStyle]="{'width': '3%'}"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" [ngClass]="rowData.isRowHighlight ? 'file-selected-row' : ''">
              <td *ngFor="let col of columns" [hidden]="col.isHidden">
                <div *ngIf="!col.isTruncate && !col.isFailedAttachmentCol && !col.isCustomDate && !col.isNumeric">
                  <span>{{rowData[col.field]}}</span>
                </div>
                <div *ngIf="!!col.isTruncate && !col.isCustomDate && !col.isNumeric" class="ellipsis cursor-hand">
                    <!-- <span (click)="completePopupData(col,rowData[col.field])">{{rowData[col.field]}}</span> -->
                  <span *ngIf="col.field !== 'sharedCalendars'" (click)="completePopupData(col,rowData[col.field])">{{rowData[col.field]}}</span> <!--added shared cal UI fix-->
                  <span *ngIf="col.field == 'sharedCalendars'" (click)="completePopupData(col,rowData[col.field])">{{rowData[col.field] !=null?rowData[col.field].length>50?rowData[col.field].substring(0,50)+'.....':rowData[col.field]:''}}</span> <!--added shared cal UI fix-->
                </div>
                <div
                  *ngIf="!col.isTruncate && !!col.isAttachmentSummaryCol && !col.isCustomDate && !col.isNumeric">
                  <label *ngIf="!!rowData.attachmentSummary.totalAttachments"><strong translate>MySearchHistory.TotalAttachments
                  </strong><span>{{rowData.attachmentSummary.totalAttachments}}</span>&nbsp;&nbsp;</label>
                  <label *ngIf="!!rowData.attachmentSummary.downloadedAttachment"><strong translate>MySearchHistory.DownloadedAttachments
                  </strong><span>{{rowData.attachmentSummary.downloadedAttachment}}</span>&nbsp;&nbsp;</label>
                  <label *ngIf="!!rowData.attachmentSummary.totalErrorFiles && rowData.attachmentSummary.totalErrorFiles!==0"><strong translate>MySearchHistory.FailedAttachments
                  </strong>&nbsp;&nbsp;</label>
                  <label *ngIf="!!rowData.attachmentSummary.totalErrorFiles && rowData.attachmentSummary.totalErrorFiles!==0" translate>&nbsp;&nbsp; MySearchHistory.TotalErrorFiles
                  <span>{{rowData.attachmentSummary.totalErrorFiles}}</span>&nbsp;&nbsp;</label>
                  <label *ngIf="!!rowData.attachmentSummary.permissionErrorFiles && rowData.attachmentSummary.permissionErrorFiles!==0" translate>&nbsp;&nbsp;MySearchHistory.PermissionErrorFiles 
                  <span>{{rowData.attachmentSummary.permissionErrorFiles}}</span>&nbsp;&nbsp;</label>
                  <label *ngIf="!!rowData.attachmentSummary.totalErrorFiles && rowData.attachmentSummary.totalErrorFiles!==0 && rowData.status==='Success'" translate>&nbsp;&nbsp;MySearchHistory.DownloadErrorLog
                      <button *ngIf="rowData.isFailedAttactments" class="buttonInGrid" type="button" pButton
                    icon="fa fa-download" title="{{'Common.DownloadError' | translationPipe}}"
                    (click)="downloadErrorLog(rowData)"></button>&nbsp;&nbsp;</label>
                  
                </div>
                <div *ngIf="!!col.isDate && !!col.isCustomDate && !col.isNumeric">
                  <span>{{rowData[col.field] | date:'MM/dd/y'}}</span>
                </div>
                <div *ngIf="!!col.isNumeric" class="float-right">
                  <span>{{rowData[col.field]}}</span>
                </div>
              </td>
              <td>
                <button *ngIf="rowData.isSearchSuccess" class="buttonInGrid" type="button" pButton icon="fa fa-download"
                  title="{{'Common.Download' | translationPipe}}"
                  (click)="downloadZipFile(rowData)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <th colspan="5">
              <div class="text-center emptyMsg" translate>
                Common.NoRecordsFound
              </div>
            </th>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-panel>

  <div class="individual-loader-container" *ngIf="isLoading">
    <div class="individual-loader"></div>
    <div class="individual-overlay">
    </div>
  </div>
</div>
<p-dialog minWidth="200" minHeight="80" width="700" [(visible)]="showPopUpdata" [closable]="false" modal="modal"
  [responsive]="true" styleClass="folderpath-dialog">
  <p-header>
    <span class="headerTitle folderPathTitle">{{colPopupHeader}}</span>
    <a class="btn-sm float-right" (click)="cancel()">
      <i aria-hidden="true" class="fa fa-times"></i>
    </a>
  </p-header>
  <div class="folderpath-content">{{completePopUpValue}}</div>
</p-dialog>


<p-toast ></p-toast>