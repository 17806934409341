import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SearchCalendarService {

  private searchCalendarRoute = environment.serverUrl + '/searchCalendar';

  constructor(private http: HttpClient) { }

  initiateSearchRequest(form: any, users: string, sessionId: string, userEmail: string, freshSearch: string): Observable<any > {

    const formData: FormData = new FormData();

    let fromDateTempString = '';
    let toDateTempString = '';
    
    if (!!form.fromDate) {
      const fromDateTemp = moment(form.fromDate, 'YYYY-MM-DD HH:mm:ss a ZZ');
      fromDateTemp.set({ h: 0, m: 0, s: 0});
      fromDateTempString = moment(fromDateTemp).format('YYYY/MM/DD HH:mm:ss');
    }

    if (!!form.toDate) {
      const toDateTemp = moment(form.toDate, 'YYYY-MM-DD HH:mm:ss a ZZ');
      toDateTemp.set({ h: 23, m: 59, s: 59});
      toDateTempString  = moment(toDateTemp).format('YYYY/MM/DD HH:mm:ss');
    }

    formData.append('users', users);
    if (!!form.keyword) {
      formData.append('keyword', form.keyword);
    }
    if (!!fromDateTempString) {
      formData.append('from_date', fromDateTempString);
    }
    if (!!toDateTempString) {
      formData.append('to_date', toDateTempString);
    }
    formData.append('show_deleted_events', form.showDeletedEvents);
    formData.append('only_single_events', form.onlySingleEvents);
    formData.append('include_attachments', form.includeAttachments);
    formData.append('separate_ics', form.separateICSFiles);
    formData.append('session_id', sessionId);
    formData.append('user_email', userEmail);
    formData.append('fresh_search', freshSearch);
    return this.http.post(this.searchCalendarRoute, formData)
      .pipe(map((res: any) => res),
      catchError(error => throwError(error.message || "server error.")));
  }
}
