
import {throwError as observableThrowError, } from 'rxjs';
import { Injectable } from '@angular/core';
import { Logger } from '../logger.service';
import { environment } from 'environments/environment';
import { WindowutilityService } from '../windowutility.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NavUtilityService } from '../../shared/services/nav-utility.service';


const log = new Logger('OAuthAuthenticationService');
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class OAuthAuthenticationService {

  redirectUrl = environment.webUrl + '/oauth2callback';
  handleAuthCodeRoute: string = environment.serverUrl + '/handleAuthCode';
  shareQueryString = '&shareId='
 constructor(private http: HttpClient,
    private navUtilityService: NavUtilityService,
    private windowutilityService: WindowutilityService) {
    }

  /**
   * Authenticates the user.
   * @param {LoginContext} context The login parameters.
   * @return {Observable<Credentials>} The user credentials.
   */
  login() {
    this.windowutilityService.nativeWindow.location.href = 'https://accounts.google.com/o/oauth2/auth?redirect_uri=' +
      this.redirectUrl + '&response_type=code&client_id=' + environment.clientId +
      '&scope=' + environment.oAuthScope + '&access_type=offline';
  }

  logout() {
    this.windowutilityService.nativeWindow.location.href = 'https://accounts.google.com/o/oauth2/auth?redirect_uri=' +
      this.redirectUrl + '&response_type=code&client_id=' + environment.clientId +
      '&scope=' + environment.oAuthScope + '&access_type=offline&prompt=select_account'
  }
  // handleAuthCode(authCode: string, shareId?: string): Observable<any >{
  //   return this.http.get(this.handleAuthCodeRoute + authCode +
  //     '&redirect_uri=' + this.redirectUrl)
  //     .pipe(map((res: any) => res), catchError(error => throwError(error.message || "server error.")));
  // }

  handleAuthCode(authCode: string) {
		let params = new HttpParams();
		params = params.set('code', authCode);
		params = params.set('redirect_uri', this.redirectUrl)
		return this.http
			.get(this.handleAuthCodeRoute, { params })
      .pipe(
        map((res : any) => res),
        catchError((error :any) => throwError(error))
      )
	}
}
