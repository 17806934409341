import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'repoLevelPermissionConversion'
})
export class RepoLevelPermissionConversionPipe implements PipeTransform {

  transform(value: string): string {
    if (value === 'fullAccess') {
      return 'Admin';
    } else if (value === 'editors') {
      return 'Editor';
    } else if (value === 'viewers'){
      return 'Viewer';
    }else if(value==='All'){
      return 'All'
    }else{
      return null;
    }
  }
}
