import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MySearchHistoryService } from './my-search-history.service';
import { AuthenticationService } from 'app/core/authentication/authentication.service';
import { takeWhile, finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Message } from 'primeng/api';
import { Table } from 'primeng/table';
import { WindowutilityService } from 'app/core/windowutility.service';
import { SharedApiUtilityService } from 'app/shared/services/shared-api-utility.service';
import * as moment from 'moment';
import { CommonUtilityService } from 'app/shared/services/common-utility.service';
import { NavUtilityService } from 'app/shared/services/nav-utility.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'pwc-my-search-history',
  templateUrl: './my-search-history.component.html',
  styleUrls: ['./my-search-history.component.scss']
})
export class MySearchHistoryComponent implements OnInit {

  searchHistoryGridColumn: any[];
  panelTitle = 'MySearchHistory.PanelTitle';
  searchHistoryList: any[] = [];
  isLoading = false;
  userEmail = '';
  // mySearchHistoryGrowlMessage: Message[] = [];
  @ViewChild('grid') grid: Table;
  rowsPerPageOptions: number[] = [20, 50, 100, 200];
  selectedrowsPerPageOption: number = this.rowsPerPageOptions[0];
  first = 0;
  showPopUpdata = false;
  colPopupHeader = '';
  completePopUpValue = '';
  nativeWindowReference: any;
  sessionId: string;
  link = '';
  linkPresent = false;
  private alive = true;

  constructor(private mySearchHistoryService: MySearchHistoryService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private windowutilityService: WindowutilityService,
    private ref: ChangeDetectorRef,
    private commonUtilityService: CommonUtilityService,
    private navUtilityService: NavUtilityService) {
    this.userEmail = this.authenticationService.credentials ?
      this.authenticationService.credentials.userEmail : '';
    this.nativeWindowReference = windowutilityService.nativeWindow;
    this.sessionId = this.authenticationService.credentials
            ? this.authenticationService.credentials.sessionId
            : null;
   }

  ngOnInit() {

    this.searchHistoryGridColumn = [
      {
        field: 'searchId', isHidden: true, isTextFilter: false, isDate: false, isNumeric: false,
        isFilterApplied: false, isShowOverlay: false, isHc: false,  isTruncate: false, isAttachmentSummaryCol: false,
      },
      {
        field: 'email', header: 'E-mail', isHidden: false, isDate: false, isNumeric: false,
        isTextFilter: false, isDateFilter: true, isFilterApplied: false, isShowOverlay: false,
        selectedFilterType: 'Exact', class: 'emailClass', isTruncate: true, isAttachmentSummaryCol: false
      },
      {
        field: 'searchTime', header: 'SearchTime', isHidden: false, isDate: true, isNumeric: false,
        isTextFilter: false, isDateFilter: true, isFilterApplied: false, isShowOverlay: false,
        selectedFilterType: 'Exact', class: 'searchTimeClass', isTruncate: false, isAttachmentSummaryCol: false
      },
      {
        field: 'keyword', header: 'Keyword', isHidden: false, isDate: false, isNumeric: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'keywordClass', isSearchId: false, isTruncate: true, isAttachmentSummaryCol: false
      },
      {
        field: 'fromDate', header: 'From Date', isHidden: false, isDate: true, isNumeric: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'fromDateClass', isSearchId: false, isTruncate: false, isAttachmentSummaryCol: false,
        isCustomDate: true
      },
      {
        field: 'toDate', header: 'To Date', isHidden: false, isDate: true, isNumeric: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'toDateClass', isSearchId: false, isTruncate: false, isAttachmentSummaryCol: false,
        isCustomDate: true
      },
      {
        field: 'noOfEvents', header: 'Total Matching Events', isHidden: false, isDate: false, isNumeric: true,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'noOfEventsClass', isSearchId: false, isTruncate: false, isAttachmentSummaryCol: false
      },
      {
        field: 'totalCalendars', header: 'Total Calendars', isHidden: false, isDate: false, isNumeric: true,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: false,
        selectedFilterType: 'Contains', class: 'totalCalendarsClass', isSearchId: false, isTruncate: false, isAttachmentSummaryCol: false
      },
      {
        field: 'sharedCalendars', header: 'Shared Calendars', isHidden: false, isDate: false, isNumeric: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'sharedCalendarsClass', isSearchId: false, isTruncate: true, isAttachmentSummaryCol: false
      },
      {
        field: 'AttachmentSummary', header: 'Attachment Summary', isHidden: false, isDate: false, isNumeric: false,
        isTextFilter: false, isDateFilter: true, isFilterApplied: false, isShowOverlay: false,
        selectedFilterType: 'Exact', class: 'attachmentSummaryClass', isTruncate: false, isAttachmentSummaryCol: true
      },
      {
        field: 'status', header: 'Status', isHidden: false, isDate: false, isNumeric: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'statusClass', isTruncate: false, isAttachmentSummaryCol: false
      }
    ];

    this.initialLoad();

  }

  initialLoad() {
    this.navUtilityService.menuButtonClicked.next({title: 'My Search History'});
    let isRequestPending = true;
    setTimeout(() => {
      if (isRequestPending) {
        this.isLoading = true;
      }
    }, 500);
    this.mySearchHistoryService.getMySearchHistoryList(this.userEmail, this.sessionId)
    .pipe( takeWhile(() => this.alive),
      finalize(() => {
        isRequestPending = false;
        this.isLoading = false;
      }))
      .subscribe(
        (response: any) => {
          this.searchHistoryList = [];
          this.isLoading = false;
          if (!!response && response.my_searches.length > 0) {
            this.populateMySearchHistoryList(response.my_searches);
          }
        },
        (error) => {
          this.isLoading = false;
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('Common.Error'),
            detail: error
          });

        }
      );

  }
  populateMySearchHistoryList(searcheHistoryResponseList: any[]) {
    searcheHistoryResponseList.forEach((searchHistoryRecord: any) => {
      const searchTimeLocal = this.commonUtilityService.convertUTCToLocalDate(searchHistoryRecord.search_time);
      const attachmentSummary = {
        totalAttachments: searchHistoryRecord.total_attachments,
        downloadedAttachment: searchHistoryRecord.downloaded_attachments,
        totalErrorFiles: searchHistoryRecord.total_error_files,
        permissionErrorFiles: searchHistoryRecord.permission_error_files,
      }

      const searchObj = {
        searchId: searchHistoryRecord.search_id,
        searchTime: searchTimeLocal,
        email: searchHistoryRecord.email,
        keyword: searchHistoryRecord.keyword,
        fromDate: searchHistoryRecord.from_date,
        toDate: searchHistoryRecord.to_date,
        noOfEvents: searchHistoryRecord.no_of_events,
        noOfCalendars: searchHistoryRecord.no_of_calendars,
        attachmentSummary: attachmentSummary,
        isFailedAttactments: (!!searchHistoryRecord.total_error_files) ? true : false,
        sharedCalendars: searchHistoryRecord.shared_calendars,
        totalCalendars: searchHistoryRecord.total_calendars,
        status: searchHistoryRecord.status,
        downloadUrl: searchHistoryRecord.download_url,
        downloadErrorUrl: searchHistoryRecord.download_error_url,
        isSearchSuccess: (searchHistoryRecord.status.toLowerCase() === 'success'
         &&  searchHistoryRecord.no_of_events !== 0 ) ? true : false
      };
      this.searchHistoryList.push(searchObj);
    });
    this.grid.value = this.searchHistoryList;
    this.ref.detectChanges();

  }

  completePopupData(col: any, data: string) {
    this.showPopUpdata = true;
    this.completePopUpValue = data;
    this.colPopupHeader = col.header;
  }

  cancel() {
    this.showPopUpdata = false;
    this.completePopUpValue = '';
    this.colPopupHeader = '';
  }

  downloadZipFile(rowData: any) {
    this.sleep(3000);
    const link = document.createElement('a');
    link.href = rowData.downloadUrl;
    link.click();
    this.sleep(3000);
  }

  downloadErrorLog(rowData: any) {
    this.sleep(3000);
    const link = document.createElement('a');
    link.href = rowData.downloadErrorUrl;
    link.click();
    this.sleep(3000);
  }

  sleep(milliseconds: number) {
    const start = new Date().getTime();
    for (let i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }

  refreshSearchHistoryGrid() {
    this.initialLoad();
  }

  exportData() {
    let isRequestPending = true;
    const today = new Date();
    const currentTimeString = moment(today).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    setTimeout(() => {
      if (isRequestPending) {
        this.isLoading = true;
      }
    }, 1000);
    this.mySearchHistoryService.exportData(currentTimeString, this.userEmail, this.sessionId)
    .pipe(takeWhile(() => this.alive),
      finalize(() => {
        isRequestPending = false;
        this.isLoading = false;
      }))
      .subscribe((response: any)  => {
        // this.link = data.report_path;
        // this.linkPresent = true;
        
            let fileName;
            const contentDisposition = response.headers.get('content-disposition');
            if (contentDisposition) {
              const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = fileNameRegex.exec(contentDisposition);
              if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
              }
            }
  
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response.body);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
            downloadLink.setAttribute('download', fileName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            
      }, error => {
        this.messageService.clear();
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('Common.Error')
          // detail: response.report_path
        });
      }
    );
  }

  resetLink() {
    this.linkPresent = false
  }
}
