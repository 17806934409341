import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; 

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './home/home.module';
import { Oauth2Module } from './oauth2/oauth2.module';

import { RequestInterceptor } from './core/authentication/request.interceptor';
import { ResponseInterceptor } from './core/authentication/response.interceptor';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MenuItem } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { FileUploadModule } from 'primeng/fileupload';
import { TreeModule } from 'primeng/tree';
import { FieldsetModule } from 'primeng/fieldset';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { MenubarModule } from 'primeng/menubar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabMenuModule } from 'primeng/tabmenu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { DialogModule } from 'primeng/dialog';
import { MessageService } from 'primeng/api';
import { PaginatorModule } from 'primeng/paginator';
import {ToastModule} from 'primeng/toast';
// import { Table } from 'primeng/table';
import {DataViewModule} from 'primeng/dataview';
import { DragDropModule } from 'primeng/dragdrop';
import { PanelModule } from 'primeng/panel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';


import { WindowutilityService } from './core/windowutility.service';


import { GroupTypeCasingPipe } from './shared/pipe/group-type-casing.pipe';

import { AccessDeniedModule } from './access/access-denied/access-denied.module';
import { PermissionTypeConversionPipe } from './shared/pipe/permission-type-conversion.pipe';
import { DatePipe } from '@angular/common';
import { RepoLevelPermissionConversionPipe } from './shared/pipe/repo-level-permission-conversion.pipe';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ListToCommaseparatedPipe } from './shared/pipe/list-to-commaseparated.pipe';
import { LogoutComponent } from './logout/logout.component';
import { SearchCalendarComponent } from './search-calendar/search-calendar.component';
import { MySearchHistoryComponent } from './my-search-history/my-search-history.component';
import { LogsAndReportComponent } from './logs-and-report/logs-and-report.component';
import { SearchCalendarService } from './search-calendar/search-calendar.service';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgbModule,
    NgIdleKeepaliveModule.forRoot(),
    CoreModule,
    SharedModule,
    HomeModule,
    AccessDeniedModule,
    Oauth2Module,
    AppRoutingModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    PanelMenuModule,
    TabViewModule,
    FileUploadModule,
    DragDropModule,
    // Table,
    DataViewModule,
    PanelModule,
    DialogModule,
    TreeModule,
    ToastModule,
    AccordionModule,
    AutoCompleteModule,
    CheckboxModule,
    DropdownModule,
    RadioButtonModule,
    FieldsetModule,
    CalendarModule,
    BrowserAnimationsModule,
    MenubarModule,
    PaginatorModule,
    InputMaskModule,
    TableModule,
    BreadcrumbModule,
    TreeTableModule,
    TabMenuModule
  ],
  declarations: [AppComponent,
    ListToCommaseparatedPipe, GroupTypeCasingPipe, PermissionTypeConversionPipe,
    RepoLevelPermissionConversionPipe,
    LogoutComponent,
    SearchCalendarComponent,
    MySearchHistoryComponent,
    LogsAndReportComponent],
  providers: [WindowutilityService, SearchCalendarService, MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
  ],
  exports: [
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

