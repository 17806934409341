import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ShellComponent } from './shell/shell.component';
import { MenuComponent } from './shell/menu/menu.component';
import { AuthenticationService } from './authentication/authentication.service';
import { OAuthAuthenticationService } from './authentication/google.authentication.service';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { I18nService } from './i18n.service';
import { FooterComponent } from './shell/footer/footer.component';

import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TreeModule } from 'primeng/tree';
import { MenubarModule } from 'primeng/menubar';
import { TabMenuModule } from 'primeng/tabmenu';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {PanelModule} from 'primeng/panel';
import {ToastModule} from 'primeng/toast';
import { CustomHeaderComponent } from './shell/custom-header/custom-header.component';
import { SharedModule } from '../shared/shared.module';
import {DataViewModule} from 'primeng/dataview';
import { SafeHtmlPipe } from '../shared/pipe/safe-html.pipe';
import {SidebarModule} from 'primeng/sidebar';
import { TableModule } from 'primeng/table';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbModule,
    ButtonModule,
    InputTextModule,
    PanelMenuModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    ToastModule,
    MenubarModule,
    DialogModule,
    HttpClientModule,
    TabMenuModule,
    SidebarModule,
    PanelModule,
    CheckboxModule
  ],
  exports: [
    MenuComponent
  ],
  declarations: [
    ShellComponent,
    MenuComponent,
    FooterComponent,
    CustomHeaderComponent,
    SafeHtmlPipe
  ],
  providers: [
    AuthenticationService,
    OAuthAuthenticationService,
    AuthenticationGuard,
    I18nService
  ]
})
export class CoreModule {

  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

}
