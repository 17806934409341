import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanToCustomvalue'
})
export class BooleanToCustomvaluePipe implements PipeTransform {

  transform(value: boolean): string {
    if (value === true) {
      return 'Yes';
    } else if (value === false) {
      return 'No';
    } else {
      return '';
    }
  }

}
