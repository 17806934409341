import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { NavUtilityService } from '../../shared/services/nav-utility.service';
import { OAuthAuthenticationService } from '../../core/authentication/google.authentication.service';


@Component({
  selector: 'pwc-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {
  currentYear: number;

  constructor(
    private authenticationService: AuthenticationService,
    private googleAuthenticationService: OAuthAuthenticationService,
    private navUtilityService: NavUtilityService) {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {

  }

  next() {
    this.authenticationService.updateCredentials(null);
    this.googleAuthenticationService.logout();
    this.navUtilityService.setIsLoggedOut(true);
  }
}
