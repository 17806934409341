<pwc-custom-header (closeMenu)="closeMenuEvent($event)">
</pwc-custom-header>
<div class="row">
    <div class="col-md-12">
        <pwc-menu [closeMenubutton]="closeMenubutton" *ngIf="(isLoggedIn|async)"></pwc-menu>
    </div>
</div>
<div class="container-fluid addMarginTop">
    <div class="row">
        <div Class="col-md-12">
            <router-outlet></router-outlet>            
        </div>
    </div>
    <p-toast ></p-toast>
</div>
<pwc-footer></pwc-footer>