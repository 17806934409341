import { Injectable } from '@angular/core';
import { WindowutilityService } from '../../core/windowutility.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { NavUtilityService } from '../../shared/services/nav-utility.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedApiUtilityService } from 'app/shared/services/shared-api-utility.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import * as moment from 'moment';

export interface FilePermission {
  isReader: boolean;
  isWriter: boolean;
}

@Injectable()
export class CommonUtilityService {
  filePermission: FilePermission;
  nativeWindowReference: any;
  uIConstants:any
  adminPermissionTypes: any[]=[{
    'label': 'Admin',
    'value': 'fullAccess'
  }, {
    'label': 'Editor',
    'value': 'editors'
  }, {
    'label': 'Viewer',
    'value': 'viewers'
  }];;
  editorPermissionTypes: any[]=[{
    'label': 'Editor',
    'value': 'editors'
  }, {
    'label': 'Viewer',
    'value': 'viewers'
  }];;
  viewerPermissionTypes: any[]=[{
    'label': 'Viewer',
    'value': 'viewers'
  }];;

  constructor(private windowutilityService: WindowutilityService,
    private navUtilityService: NavUtilityService,
    private sharedApiUtilityService: SharedApiUtilityService,
    private translateService: TranslateService) {
    this.uIConstants = {
      minYear : 1970,
      maxYearRange : 50
    }
      this.nativeWindowReference = this.windowutilityService.nativeWindow;
     }

  getAdminPermissionTypes() {
    return of(this.adminPermissionTypes);
  }
   
  getConstants(key: string) {
    return this.uIConstants[key];
  }
  
  getFileExtension(filename: string) {
    if (!filename)
      return null;
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
  }

  getFileName(filename: string) {
    if (!filename)
      return null;
    let index: number = filename.indexOf('.');
    if (index == -1) {
      return filename;
    } else {
      return filename.slice(0, filename.lastIndexOf('.'));
    }
  }

  setSelectForDropdown(options:any[]){
    options.unshift({label:this.translateService.instant('Common.DropdownPlaceholder'),value:""})
    return options;
  }

  OrderByArray(values: any[], orderType: any) {
    return values.sort((a, b) => {
      if (a[orderType] < b[orderType]) {
        return -1;
      }

      if (a[orderType] > b[orderType]) {
        return 1;
      }

      return 0;
    });
  }

  
  OrderByNumberArray(values: any[], orderType: any) {
    return values.sort((a, b) => {
      if (Number(a[orderType]) < Number(b[orderType])) {
        return -1;
      }

      if (Number(a[orderType]) > Number(b[orderType])) {
        return 1;
      }

      return 0;
    });
  }

  OrderByStringArray(values: any[], orderType: any) {
    values.reverse();
    return values.sort((a, b) => {

      if (a[orderType].toLowerCase() < b[orderType].toLowerCase()) {
        return -1;
      }

      if (a[orderType].toLowerCase() > b[orderType].toLowerCase()) {
        return 1;
      }

      return 0;
    });

  }
  
  
  
  randomValue(length?: number): string {
    var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
    var value = "";
    length = !!length ? length : chars.length;
    for (var x = 0; x < length; x++) {
      var i = Math.floor(Math.random() * chars.length);
      value += chars.charAt(i);
    }
    return value;
  }

  getCookie(cname: any) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  isFeatureAccessible(featureName: string, featureList: any[]): boolean {
    if (!!featureList && featureList.length > 0) {
      return featureList.findIndex(x => x == featureName) == -1 ? false : true;
    }
    else {
      return false;
    }
  }


  dateDifferenceCheck(toDate:any, fromDate:any){
    var aYearFromNow = new Date(fromDate);
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

    if(aYearFromNow.getTime()>toDate.getTime()){
      return true;
    }else{
      return false;
    }
    }


    convertUTCToLocalDate(utcDate: string) {
      const searchTime = new Date(utcDate);
      const searchTimeUTC = new Date(searchTime + 'UTC')
      const searchTimeLocal = moment(searchTimeUTC).local().format('MM/DD/YYYY HH:mm:ss');
      return searchTimeLocal;
    }
 
}
