import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'app/core/authentication/authentication.service';
import { Router } from '@angular/router';
import {Message} from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonUtilityService } from 'app/shared/services/common-utility.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LogsAndReportService } from './logs-and-report.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile, finalize } from 'rxjs/operators';
import { WindowutilityService } from 'app/core/windowutility.service';
import { NavUtilityService } from '../shared/services/nav-utility.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'pwc-logs-and-report',
  templateUrl: './logs-and-report.component.html',
  styleUrls: ['./logs-and-report.component.scss']
})
export class LogsAndReportComponent implements OnInit {

  logsAndReportsGridColumn: any[] = [];
  logsAndReportsList: any[] = [];
  panelTitle = 'LogsAndReports.PanelTitle';
  isLoading = false;
  userEmail = '';
  // logsAndReportGrowlMessage: Message[] = [];
  @ViewChild('grid') grid: Table;
  rowsPerPageOptions: number[] = [20, 50, 100, 200];
  selectedrowsPerPageOption: number = this.rowsPerPageOptions[0];
  nativeWindowReference: any;
  sessionId: string;
  logsAndReportForm: FormGroup;
  first = 0;
  createdFromDateUTC = '';
  createdToDateUTC = '';
  createdFromDateUserTime = '';
  createdToDateUserTime = '';
  noLogsFlag = true;
  offsetValue = '';
  limit = '50';
  minYear: number;
  maxYear: number;
  link = '';
  linkPresent = false;
  showPopUpdata = false;
  colPopupHeader = '';
  completePopUpValue = '';
  private alive = true;

  constructor(private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private windowutilityService: WindowutilityService,
    private messageService: MessageService,
    private fb: FormBuilder, private logsAndReportService: LogsAndReportService,
    private router: Router, private commonUtilityService: CommonUtilityService,
    private navUtilityService: NavUtilityService) {
      this.minYear = this.commonUtilityService.getConstants('minYear');
      this.maxYear = new Date().getFullYear() + this.commonUtilityService.getConstants('maxYearRange');
      this.nativeWindowReference = windowutilityService.nativeWindow;
    this.userEmail = this.authenticationService.credentials ?
      this.authenticationService.credentials.userEmail : '';
    this.sessionId = this.authenticationService.credentials
      ? this.authenticationService.credentials.sessionId
      : null;
  }

  ngOnInit() {

    this.logsAndReportForm = this.fb.group({
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
    });

    if (this.authenticationService.credentials.isAdmin === false) {
      this.router.navigate(['/access-denied']);
    }

    this.logsAndReportsGridColumn = [
      {
        field: 'searchId', isHidden: true, isTextFilter: false, isDate: false, isNumeric: false,
        isFilterApplied: false, isShowOverlay: false, isHc: false, isSearchCriteria: false,  isTruncate: false
      },
      {
        field: 'userId', header: 'User ID', isHidden: false, isDate: false, isNumeric: false,
        isTextFilter: false, isDateFilter: true, isFilterApplied: false, isShowOverlay: false,
        selectedFilterType: 'Exact', class: 'userIdClass', isSearchCriteria: false,  isTruncate: true
      },
      {
        field: 'country', header: 'Country', isHidden: false, isDate: false, isNumeric: false,
        isTextFilter: false, isDateFilter: true, isFilterApplied: false, isShowOverlay: false,
        selectedFilterType: 'Exact', class: 'countryClass', isSearchCriteria: false,  isTruncate: false
      },
      {
        field: 'searchTime', header: 'Search Time', isHidden: false, isDate: true,  isCustomDate: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isNumeric: false,
        selectedFilterType: 'Contains', class: 'searchTimeClass', isSearchCriteria: false,  isTruncate: false
      },
      {
        field: 'searchCriteria', header: 'Search Criteria', isHidden: false, isDate: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true, isNumeric: false,
        selectedFilterType: 'Contains', class: 'searchCriteriaClass', isSearchCriteria: true,  isTruncate: false
      },
      {
        field: 'noOfEvents', header: 'No. of Events', isHidden: false, isDate: false, isNumeric: true,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'noOfEventsClass', isSearchCriteria: false,  isTruncate: false
      },
      {
        field: 'status', header: 'Status', isHidden: false, isDate: false, isNumeric: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'statusClass', isSearchCriteria: false,  isTruncate: false
      },
      {
        field: 'loginTime', header: 'Log In Time', isHidden: false, isDate: true,  isCustomDate: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true, isNumeric: false,
        selectedFilterType: 'Contains', class: 'loginTimeClass', isSearchCriteria: false,  isTruncate: false
      },
      {
        field: 'logOutTime', header: 'Log Out Time', isHidden: false, isDate: true,  isCustomDate: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true, isNumeric: false,
        selectedFilterType: 'Contains', class: 'logOutTimeClass', isSearchCriteria: false,  isTruncate: false
      }
    ];

    this.initialLoad();
  }


  initialLoad() {

    this.navUtilityService.menuButtonClicked.next({title: 'Logs and Reports'});
    this.logsAndReportsList = [];
    this.searchReport();
  }

  searchReport(searchParam?: any) {
    let status = '';
    this.noLogsFlag = true;
    this.linkPresent = false
    const datePipe = new DatePipe('en-US');
    let isRequestPending = true;
    setTimeout(() => {
      if (isRequestPending) {
        this.isLoading = true;
      }
    }, 500);
    this.logsAndReportsList = [];
    if (!!searchParam) {

      const createdFromDateUserTime = moment(searchParam.fromDate, 'YYYY-MM-DD HH:mm:ss');
      const createdToDateTempUserTime = moment(searchParam.toDate, 'YYYY-MM-DD HH:mm:ss');
  
      createdFromDateUserTime.set({ h: 0, m: 0, s: 0 });
      createdToDateTempUserTime.set({ h: 23, m: 59, s: 59 });

      this.createdFromDateUserTime = moment(createdToDateTempUserTime).format('YYYY/MM/DD HH:mm:ss');
      this.createdToDateUserTime = moment(createdToDateTempUserTime).format('YYYY/MM/DD HH:mm:ss');
  
      this.createdFromDateUTC = moment(createdFromDateUserTime).utc().format('YYYY/MM/DD HH:mm:ss');
      this.createdToDateUTC = moment(createdToDateTempUserTime).utc().format('YYYY/MM/DD HH:mm:ss');
    }
   

    this.logsAndReportService.getReportList(true, this.offsetValue,
      this.limit, this.createdFromDateUTC, this.createdToDateUTC, this.userEmail, this.sessionId)
      .pipe(takeWhile(() => this.alive),
        finalize(() => {
          isRequestPending = false;
          this.isLoading = false;
        }))
      .subscribe((data: any) => {
        status = data.status
        if (!!status && status.toLowerCase() === 'success' && !!data.logs_report) {
          this.grid.reset();
          this.populateReportList(data);
        } else {
          this.logsAndReportsList = [];
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('Common.Error'),
            detail: data.status_msg
          });
        }
      });
  }


  populateReportList(list: any) {

    const reports: any[] = list.logs_report;
    this.noLogsFlag = false;
    if (!!reports && reports.length > 0) {
      reports.forEach((report: any) => {
        const searchTimeLocal = report.search_time ? this.commonUtilityService.convertUTCToLocalDate(report.search_time) : '';
        const loginTimeLocal = report.login_time ? this.commonUtilityService.convertUTCToLocalDate(report.login_time) : '';
        const logoutTimeLocal = report.logout_time ? this.commonUtilityService.convertUTCToLocalDate(report.logout_time) : '';
        const searchCriteria = {
          userSearched: report.user_searched,
          keyword: report.keyword,
          fromDate: report.from_date,
          toDate: report.to_date,
          deletedEvents: report.deleted_events === true ? 'Yes' : 'No',
          singleEvents: report.single_events === true ? 'Yes' : 'No',
          includeAttachments: report.include_attachments === true ? 'Yes' : 'No',
          separateICSFiles: report.separate_ics_files === true ? 'Yes' : 'No'
        }

        const reportObj = {
          userId: report.user_id,
          searchId: report.search_id,
          country: report.country,
          searchTime: searchTimeLocal,
          searchCriteria: searchCriteria,
          noOfEvents: report.no_of_events,
          status: report.status,
          loginTime: loginTimeLocal,
          logOutTime: logoutTimeLocal,
        };
        this.logsAndReportsList.push(reportObj);
      });
      this.logsAndReportsList = [...this.logsAndReportsList];
      this.grid.value = this.logsAndReportsList;
    }
  }

  exportData() {
    let isRequestPending = true;
    
    const today = new Date();
    const currentTimeString = moment(today).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    setTimeout(() => {
      if (isRequestPending) {
        this.isLoading = true;
      }
    }, 1000);
    this.logsAndReportService.exportData(currentTimeString, this.userEmail, this.sessionId,
      this.createdFromDateUTC, this.createdToDateUTC)
    .pipe(takeWhile(() => this.alive),
      finalize(() => {
        isRequestPending = false;
        this.isLoading = false;
      }))
      .subscribe((response: any)  => {
          // this.link = data.report_path;
          // this.linkPresent = true;
          
              let fileName;
              const contentDisposition = response.headers.get('content-disposition');
              if (contentDisposition) {
                const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = fileNameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                  fileName = matches[1].replace(/['"]/g, '');
                }
              }
    
              let dataType = response.type;
              let binaryData = [];
              binaryData.push(response.body);
              let downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
              downloadLink.setAttribute('download', fileName);
              document.body.appendChild(downloadLink);
              downloadLink.click();
              
        }, error => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('Common.Error')
            // detail: response.report_path
          });
        }
      );
  }

  resetLink() {
    this.linkPresent = false
  }
  reset() {
    this.logsAndReportForm.reset();
    this.createdFromDateUTC = '';
    this.createdToDateUTC = '';
    this.initialLoad();
  }

  completePopupData(col: any, data: string) {
    this.showPopUpdata = true;
    this.completePopUpValue = data;
    this.colPopupHeader = col.header;
  }

  cancel() {
    this.showPopUpdata = false;
    this.completePopUpValue = '';
    this.colPopupHeader = '';
  }
}
