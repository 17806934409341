<div class="gcp-container">
  <div class="container-fluid">
      <div class="jumbotron text-center">
          <h3>
              <span *ngIf="!!displaySessionTimeOutMessage" translate>LogoutComponent.SessionTimedOut</span>
              <span translate>LogoutComponent.LogoutMessage</span>
          </h3>
          <p>
            Please click <span class='loginLink' (click)='login()'>here</span> to login.
          </p>
      </div>
  </div>
  <div class="individual-loader-container" *ngIf="isLoading">
      <div class="individual-loader"></div>
      <div class="individual-overlay"></div>
  </div>
</div>