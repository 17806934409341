import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NavUtilityService } from '../../shared/services/nav-utility.service';
import { Message } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  // displayGrowlMessage: Message[] = [];
  isLoggedIn: Observable<boolean>;
  closeMenubutton = false;
  private alive  = true;
 

  constructor(private navUtilityService: NavUtilityService,
    private translateService: TranslateService,
    private ref: ChangeDetectorRef) {
      this.isLoggedIn = navUtilityService.isLoggedIn();
     }

  ngOnInit() {
    }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.alive = false;
  }
  closeMenuEvent(closeMenu: boolean) {
    this.closeMenubutton = closeMenu;
    this.ref.detectChanges();
  }
}
