import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'app/core/authentication/authentication.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SharedApiUtilityService } from 'app/shared/services/shared-api-utility.service';
import { finalize, takeWhile } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { CommonUtilityService } from 'app/shared/services/common-utility.service';
import { NavUtilityService } from 'app/shared/services/nav-utility.service';
import { SearchCalendarService } from './search-calendar.service';
import { Table } from 'primeng/table';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'pwc-search-calendar',
  templateUrl: './search-calendar.component.html',
  styleUrls: ['./search-calendar.component.scss']
})
export class SearchCalendarComponent implements OnInit {

  userEmail = '';
  searchEventForm: FormGroup;
  panelTitle = 'SearchCalendar.panelTitle';
  userEmails: any[] = [];
  UserEmailList: any[] = [];
  filteredOwnersMultiple: any[] = [];
  // searchCalendarGrowlMessage: Message[] = [];
  minYear: number;
  maxYear: number;
  paginationReq = false;
  offsetValue = 10;
  limit = 10;
  isSearchedInFourteenDays = false;
  noEventFlag = false;
  searchList: any[] = [];
  searchListColumn: any[] = [];
  userListSearchedRecently = 'dhanalaxmi.a.moolya@dev.pwc.com';
  sessionId: string;
  isLoading = false;
  @ViewChild('grid') grid: Table;
  rowsPerPageOptions: number[] = [20, 50, 100, 200];
  selectedrowsPerPageOption: number = this.rowsPerPageOptions[0];
  first = 0;
  searchInput: any;
  usersEmailSearchedInFourteenDaysList: any[] = [];
  private alive = true;

  constructor(private authenticationService: AuthenticationService,
    private sharedApiUtilityService: SharedApiUtilityService,
    private router: Router,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private messageService: MessageService,
    private navUtilityService: NavUtilityService,
    private commonUtilityService: CommonUtilityService,
    private searchCalendarService: SearchCalendarService) {

    this.userEmail = this.authenticationService.credentials ?
      this.authenticationService.credentials.userEmail : '';
      this.minYear = this.commonUtilityService.getConstants('minYear');
      this.maxYear = new Date().getFullYear() + this.commonUtilityService.getConstants('maxYearRange');
      this.sessionId = this.authenticationService.credentials
            ? this.authenticationService.credentials.sessionId
            : null;
  }

  ngOnInit() {
    this.navUtilityService.menuButtonClicked.next({title: 'Search Calendar'});
    this.searchEventForm = this.fb.group({
      userEmail: [null, Validators.required],
      keyword: [null],
      fromDate: [null],
      toDate: [null],
      showDeletedEvents: true,
      onlySingleEvents: false,
      includeAttachments: true,
      separateICSFiles: true
    });

    this.searchListColumn = [
      {
        field: 'searchId', isHidden: true, isTextFilter: false, isDate: false, isNumeric: false,
        isFilterApplied: false, isShowOverlay: false, isHc: false,  isTruncate: false, isAttachmentSummaryCol: false,
      },
      {
        field: 'email', header: 'E-mail', isHidden: false, isDate: false, isNumeric: false,
        isTextFilter: false, isDateFilter: true, isFilterApplied: false, isShowOverlay: false,
        selectedFilterType: 'Exact', class: 'emailClass', isTruncate: true, isAttachmentSummaryCol: false
      },
      {
        field: 'searchTime', header: 'SearchTime', isHidden: false, isDate: true, isNumeric: false,
        isTextFilter: false, isDateFilter: true, isFilterApplied: false, isShowOverlay: false,
        selectedFilterType: 'Exact', class: 'searchTimeClass', isTruncate: false, isAttachmentSummaryCol: false
      },
      {
        field: 'keyword', header: 'Keyword', isHidden: false, isDate: false, isNumeric: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'keywordClass', isSearchId: false, isTruncate: true, isAttachmentSummaryCol: false
      },
      {
        field: 'fromDate', header: 'From Date', isHidden: false, isDate: true, isNumeric: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'fromDateClass', isSearchId: false, isTruncate: false, isAttachmentSummaryCol: false,
        isCustomDate: true
      },
      {
        field: 'toDate', header: 'To Date', isHidden: false, isDate: true, isNumeric: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'toDateClass', isSearchId: false, isTruncate: false, isAttachmentSummaryCol: false,
        isCustomDate: true
      },
      {
        field: 'noOfEvents', header: 'Total Matching Events', isHidden: false, isDate: false, isNumeric: true,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'noOfEventsClass', isSearchId: false, isTruncate: false, isAttachmentSummaryCol: false
      },
      {
        field: 'totalCalendars', header: 'Total Calendars', isHidden: false, isDate: false, isNumeric: true,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: false,
        selectedFilterType: 'Contains', class: 'totalCalendarsClass', isSearchId: false, isTruncate: false, isAttachmentSummaryCol: false
      },
      {
        field: 'sharedCalendars', header: 'Shared Calendars', isHidden: false, isDate: false, isNumeric: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'sharedCalendarsClass', isSearchId: false, isTruncate: true, isAttachmentSummaryCol: false
      },
      {
        field: 'AttachmentSummary', header: 'Attachment Summary', isHidden: false, isDate: false, isNumeric: false,
        isTextFilter: false, isDateFilter: true, isFilterApplied: false, isShowOverlay: false,
        selectedFilterType: 'Exact', class: 'attachmentSummaryClass', isTruncate: false, isAttachmentSummaryCol: true
      },
      {
        field: 'status', header: 'Status', isHidden: false, isDate: false, isNumeric: false,
        isTextFilter: true, isFilterApplied: false, isShowOverlay: false, isName: true,
        selectedFilterType: 'Contains', class: 'statusClass', isTruncate: false, isAttachmentSummaryCol: false
      }
    ];
  }

  filterOwnerMultiple(event: any) {
    this.sharedApiUtilityService.getUsersGroupsList(event.query)
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: any) => {
        this.filteredOwnersMultiple = [];
        if (!!data.users) {
          const users = data.users;
          const userList: any = [];
          users.forEach((user: any) => {
            userList.push({ label: user.label, value: user.value});
          });
          this.filteredOwnersMultiple = userList;
        }
      });
  }

  addedUserValue(email: any) {

    if (this.UserEmailList.indexOf(email) === -1) {
      let isPresentInPermission = false;

      for (let i = 0; i < this.UserEmailList.length; i++) {
        if (!!this.UserEmailList[i] && this.UserEmailList[i].value.toLowerCase() === email.value.toLowerCase()) {
          isPresentInPermission = true;
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('Common.Error'),
            detail: this.translateService.instant('SearchCalendar.UserAlreadyAddedErrorMessage')
          });
          break;
        }
      }
      if (!isPresentInPermission) {
        this.UserEmailList.push(email);
      }
      this.searchEventForm.controls['userEmail'].setValue(this.UserEmailList);
    }
  }

  removedUserValue(email: any) {

    for (let i = 0; i < this.UserEmailList.length; i++) {
      if (this.UserEmailList[i].value.toLowerCase() === email.value.toLowerCase()) {
        this.UserEmailList.splice(i, 1);
      }
    }
    this.searchEventForm.controls['userEmail'].setValue(this.UserEmailList);
  }

  searchEvent(form: any, freshSearch: string) {
    let userEmailList;
    let usersCommaSeparatedList = '';
    this.searchInput = '';
    this.searchInput = form;
    let list: any[] = [];
    let isRequestPending = true;
    if (freshSearch === 'true') {
      userEmailList = this.usersEmailSearchedInFourteenDaysList;
      this.usersEmailSearchedInFourteenDaysList = [];
    } else {
      userEmailList = form.userEmail;
    }

    userEmailList.forEach((user: any) => {
      usersCommaSeparatedList = usersCommaSeparatedList + user.value + ',';
    });
    usersCommaSeparatedList = usersCommaSeparatedList.slice(0, -1);

    setTimeout(() => {
      if (isRequestPending) {
        this.isLoading = true;
      }
    }, 1000);
    this.searchCalendarService.initiateSearchRequest(form, usersCommaSeparatedList, this.sessionId,
      this.userEmail, freshSearch)
      .pipe( takeWhile(() => this.alive),
      finalize(() => {
        isRequestPending = false;
        this.isLoading = false;
      }))
      .subscribe(
        (response: any) => {
          if (!!response && !!response.is_searched_in_fourteen_days) {
            this.usersEmailSearchedInFourteenDaysList = [];
            this.isSearchedInFourteenDays = true;
            list = response.matching_searches;
            list.forEach((user: any) => {
              this.usersEmailSearchedInFourteenDaysList.push({label: user.email, value: user.email });
            });
            this.noEventFlag = false;
            this.searchList = [];
            this.populateMySearchHistoryList(list);
          } else {
          if (!!response && response.status === 'success') {
              this.searchInput = null;
              this.isSearchedInFourteenDays = false;
              this.navUtilityService.setShowSearchInitiatedMessage(true);
              this.navUtilityService.searchInitiated.next(true);
              this.router.navigate(['/my-search-history']);
            } else {
              this.isSearchedInFourteenDays = false;
              this.messageService.clear();
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('Common.Error'),
                detail: response.description
              });
            }
         }
        },
        (error) => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('Common.Error'),
            detail: error
          });

        }
      );

  }
  populateMySearchHistoryList(searcheHistoryResponseList: any[]) {
    searcheHistoryResponseList.forEach((searchHistoryRecord: any) => {
      const searchTimeLocal = this.commonUtilityService.convertUTCToLocalDate(searchHistoryRecord.search_time);
      const attachmentSummary = {
        totalAttachments: searchHistoryRecord.total_attachments,
        downloadedAttachment: searchHistoryRecord.downloaded_attachments,
        totalErrorFiles: searchHistoryRecord.total_error_files,
        permissionErrorFiles: searchHistoryRecord.permission_error_files,
      }
      const searchObj = { searchId: searchHistoryRecord.search_id,
        searchTime: searchTimeLocal,
        email: searchHistoryRecord.email,
        keyword: searchHistoryRecord.keyword,
        fromDate: searchHistoryRecord.from_date,
        toDate: searchHistoryRecord.to_date,
        noOfEvents: searchHistoryRecord.no_of_events,
        noOfCalendars: searchHistoryRecord.no_of_calendars,
        attachmentSummary: attachmentSummary,
        isFailedAttactments: (!!searchHistoryRecord.total_error_files) ? true : false,
        sharedCalendars: searchHistoryRecord.shared_calendars,
        totalCalendars: searchHistoryRecord.total_calendars,
        status: searchHistoryRecord.status,
        downloadUrl: searchHistoryRecord.download_url,
        downloadErrorUrl: searchHistoryRecord.download_error_url,
        isSearchSuccess: (searchHistoryRecord.status.toLowerCase() === 'success'
         &&  searchHistoryRecord.no_of_events !== 0 ) ? true : false
      };
      this.searchList.push(searchObj);
    });

  }

  reset() {
    this.searchEventForm.reset();
    this.UserEmailList = [];
    this.searchEventForm.controls['showDeletedEvents'].setValue(true);
    this.searchEventForm.controls['onlySingleEvents'].setValue(false);
    this.searchEventForm.controls['includeAttachments'].setValue(true);
    this.searchEventForm.controls['separateICSFiles'].setValue(true);
    this.isSearchedInFourteenDays = false;
    this.searchList = [];
  }

  downloadZipFile(rowData: any) {
    this.sleep(3000);
    const link = document.createElement('a');
    link.href = rowData.downloadUrl;
    link.click();
    this.sleep(3000);
  }

  downloadErrorLog(rowData: any) {
    this.sleep(3000);
    const link = document.createElement('a');
    link.href = rowData.downloadErrorUrl;
    link.click();
    this.sleep(3000);
  }
  
  sleep(milliseconds: number) {
    const start = new Date().getTime();
    for (let i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }
}
