import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../authentication/authentication.service';
import { I18nService } from '../../i18n.service';

@Component({
  selector: 'pwc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
currentYear:number;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private i18nService: I18nService) {
                this.currentYear= new Date().getFullYear();
               }

  ngOnInit() {
    
  }

}
