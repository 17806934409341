<div class="googleSearchCalendar-container">
    <p-panel class="no-header">
        <form [formGroup]="logsAndReportForm" (ngSubmit)="searchReport(logsAndReportForm.value)">
            <div class="report-container">
                <div class="row">
                  <div class="col-lg-2 col-md-2 px-3">
                    <label for="createdDateFrom" translate>LogsAndReports.DateLabelFrom
                      <span class="isRequired"> *</span>
                    </label>
                  </div>
                  <div class="col-lg-4 col-md-4 px-3">
                    <p-calendar dateFormat="mm/dd/yy" formControlName="fromDate" monthNavigator="true" yearNavigator="true"
                      class="upasCalendarControl"
                      placeholder="{{'LogsAndReports.DatePlaceholderFrom' | translationPipe}}" yearRange="{{minYear}}:{{maxYear}}"
                      [showIcon]="true" showButtonBar="true" [maxDate]="logsAndReportForm.controls['toDate'].value"></p-calendar>
                  </div>
                  <div class="col-lg-2 col-md-2 px-3">
                    <label for="createdDateTo" translate>LogsAndReports.DateLabelTo
                      <span class="isRequired"> *</span>
                    </label>
                  </div>
                  <div class="col-lg-4 col-md-4 px-3">
                    <p-calendar dateFormat="mm/dd/yy" formControlName="toDate" monthNavigator="true" yearNavigator="true"
                      class="upasCalendarControl"
                      placeholder="{{'LogsAndReports.DatePlaceholderTo' | translationPipe}}" yearRange="{{minYear}}:{{maxYear}}"
                      [showIcon]="true" showButtonBar="true" [minDate]="logsAndReportForm.controls['fromDate'].value"></p-calendar>
                  </div>
                </div>
                <br>
                <div class="row marginTop">
                    <div class="col-md-8">
                        <div class="form-group">
                            <button pButton type="submit" class="btn btn-primary btn-md sdmButton" label="{{'Common.Search' | translationPipe}}" [disabled]="!logsAndReportForm.valid"></button>
                            <button pButton type="button" class="btn btn-primary btn-md sdmButton" label="{{'Common.Reset' | translationPipe}}" (click)="reset()"></button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    <div class="row" *ngIf="(logsAndReportsList.length > 0)">
      <div class="col-md-12 col-sm-12 text-right">
        <button *ngIf="!linkPresent" pButton type="button" class="btn btn-primary btn-md sdmButton " style="float:right" (click)="exportData()"
          label="{{'LogsAndReports.ExportIcon' | translationPipe}}"></button>
        <label style="float: right" *ngIf="!!linkPresent" translate>
          LogsAndReports.Click<a [href]="link" target="_blank" class="hyperlinks" (click)="resetLink()" translate>LogsAndReports.Here</a>LogsAndReports.Exportlink
        </label>
      </div>
    </div>
    <div class="row">
        <div class="col-md-12 p-3">
          <p-table [hidden]="noLogsFlag" [value]="logsAndReportsList" [rows]="selectedrowsPerPageOption" [columns]="logsAndReportsGridColumn"
            [rowsPerPageOptions]="rowsPerPageOptions" [pageLinks]="4" [reorderableColumns]="false" [responsive]="true" 
            [paginator]="true" [resizableColumns]="true" #grid [(first)]="first" styleClass="">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field" [hidden]="col.isHidden"
                [pSortableColumnDisabled]="col.field === 'searchCriteria'" [ngStyle]="col.style" [ngClass]="col.class">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"  *ngIf="col.field !== 'searchCriteria'"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData" [ngClass]="rowData.isRowHighlight ? 'file-selected-row' : ''">
                <td *ngFor="let col of columns" [hidden]="col.isHidden">
                <div *ngIf="!!col.isTruncate && !col.isDate && !col.isNumeric && !col.isSearchCriteria"
                  class="ellipsis cursor-hand">
                  <span (click)="completePopupData(col,rowData[col.field])">{{rowData[col.field]}}</span>
                </div>
                <div *ngIf="!col.isTruncate && !!col.isDate && !col.isCustomDate">
                  <span>{{rowData[col.field] | date:'MM/dd/y hh:mm:ss'}}</span>
                </div>
                <div *ngIf="!col.isTruncate && !!col.isDate && !!col.isCustomDate">
                  <span>{{rowData[col.field] | date:'MM/dd/y'}}</span>
                </div>
                <div *ngIf="!col.isTruncate && !col.isSearchCriteria && !col.isDate && !col.isNumeric">
                  <span>{{rowData[col.field]}}</span>
                </div>
                <div *ngIf="!!col.isNumeric"  class="float-right">
                  <span>{{rowData[col.field]}}</span>
                </div>
                <div *ngIf="!col.isTruncate && !!col.isSearchCriteria">
                  <label *ngIf="rowData.searchCriteria.userSearched"><strong translate>User Email :
                    </strong><span>{{rowData.searchCriteria.userSearched}}</span>&nbsp;&nbsp;</label>
                  <label *ngIf="rowData.searchCriteria.keyword"><strong translate>Keyword :
                    </strong><span>{{rowData.searchCriteria.keyword}}</span>&nbsp;&nbsp;</label>
                  <label *ngIf="rowData.searchCriteria.fromDate"><strong translate>From Date :
                    </strong><span>{{rowData.searchCriteria.fromDate | date:'MM/dd/y'}}</span>&nbsp;&nbsp;</label>
                  <label *ngIf="rowData.searchCriteria.toDate"><strong translate>To Date :
                    </strong><span>{{rowData.searchCriteria.toDate | date:'MM/dd/y'}}</span>&nbsp;&nbsp;</label>
                  <label *ngIf="rowData.searchCriteria.deletedEvents"><strong translate>Deleted Events :
                    </strong><span>{{rowData.searchCriteria.deletedEvents}}</span>&nbsp;&nbsp;</label>
                  <label *ngIf="rowData.searchCriteria.singleEvents"><strong translate>Single Events :
                    </strong><span>{{rowData.searchCriteria.singleEvents}}</span>&nbsp;&nbsp;</label>
                  <label *ngIf="rowData.searchCriteria.includeAttachments"><strong translate>Include Attachments :
                    </strong><span>{{rowData.searchCriteria.includeAttachments}}</span>&nbsp;&nbsp;</label>
                  <label *ngIf="rowData.searchCriteria.separateICSFiles"><strong translate>Separate ICS Files :
                    </strong><span>{{rowData.searchCriteria.separateICSFiles}}</span>&nbsp;&nbsp;</label>
                </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <th colspan="5">
                <div class="text-center emptyMsg" translate>
                  Common.NoRecordsFound
                </div>
              </th>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-panel>
  
    <div class="individual-loader-container" *ngIf="isLoading">
      <div class="individual-loader"></div>
      <div class="individual-overlay">
      </div>
    </div>
  </div>
  <p-dialog minWidth="200" minHeight="80" width="700" [(visible)]="showPopUpdata" [closable]="false" modal="modal"
    [responsive]="true" styleClass="folderpath-dialog">
    <p-header>
      <span class="headerTitle folderPathTitle">{{colPopupHeader}}</span>
      <a class="btn-sm float-right" (click)="cancel()">
        <i aria-hidden="true" class="fa fa-times"></i>
      </a>
    </p-header>
    <div class="folderpath-content">{{completePopUpValue}}</div>
  </p-dialog>
  
  
  <p-toast ></p-toast>