<div class="container-fluid top-banner">
    <div class="row top-menu">
        <div class="col-sm-12 col-md-7 col-lg-6 pull-left logo-section-left">
            <img class="imgSize addMarginleft addMarginRight" src="./assets/img/pwc-logo.png" alt="pwc" />
            <span class="appNameFont addMarginleft" translate> CustomHeader.AppName</span>
        </div>
    </div>
</div>

<p-panel class="accessDeniedPanel">
    <p-header>
        <span class="headerTitle" translate>AccessDenied.Title</span>
    </p-header>
    <div class="vertical-horizontal-center">
        <div class="col-lg-12" translate>
            <div translate>
                AccessDenied.AccessDeniedMessage
            </div>
        </div>
        <div class="col-lg-12">
            <button pButton type="button" class="btn btn-primary btn-md sdmButton" label="{{ 'Common.OK' | translationPipe }}"
                (click)="next()"></button>
        </div>
    </div>
</p-panel>

<footer>
    <div class="row">
        <div class="footerdiv" translate>
            <span translate>Footer.Title1</span>
            <span translate>{{ currentYear }}</span>
            <span translate>Footer.Title2</span>
        </div>
    </div>
</footer>