import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MySearchHistoryService {

  private mySearchHistoryListRoute = environment.serverUrl + '/mySearchHistory';
  private exportLogsAndReportRoute = environment.serverUrl + '/exportSearchHistory';
  constructor(private http: HttpClient) { }


  getMySearchHistoryList(userEmail: string, sessionId: string): Observable<any >{

    const formData: FormData = new FormData();
    formData.append('user_email', userEmail);
    formData.append('session_id', sessionId);
    return this.http.post(this.mySearchHistoryListRoute, formData)
      .pipe(map((res: any) => res),
        catchError(error => throwError(error.message || "server error.")));
   }

   exportData(timeZone: string, userEmail: string, sessionId: string): Observable<any > {
    const formData: FormData = new FormData();
    formData.append('user_email', userEmail);
    formData.append('session_id', sessionId);
    formData.append('time_zone', timeZone );
    return this.http.post(this.exportLogsAndReportRoute, formData, {responseType: 'text', observe: 'response' as 'body'})
      .pipe(map((res: any) => res),
        catchError(error => throwError(error.message || "server error.")));
  }
}
