import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Route } from './core/route.service';
import { AccessDeniedComponent } from './access/access-denied/access-denied.component';
import { LogoutComponent } from './logout/logout.component';
import { HomeComponent } from './home/home.component';
import { SearchCalendarComponent } from './search-calendar/search-calendar.component';
import { MySearchHistoryComponent } from './my-search-history/my-search-history.component';
import { LogsAndReportComponent } from './logs-and-report/logs-and-report.component';

const routes: Routes = Route.withShell([
  { path: 'logout', component: LogoutComponent },
  { path: 'home', component: HomeComponent },
  { path: 'search-calendar', component: SearchCalendarComponent },
  { path: 'my-search-history', component: MySearchHistoryComponent },
  { path: 'logs-and-report', component: LogsAndReportComponent },
]);

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
