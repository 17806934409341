import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessDeniedComponent } from './access-denied.component';

const routes: Routes = ([
  { path: 'access-denied', component: AccessDeniedComponent, data: { title: 'AccessDenied.Title' } },  
]);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AccessDeniedRoutingModule { }
