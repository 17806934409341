import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, Credentials } from '../../authentication/authentication.service';
import { I18nService } from '../../i18n.service';
import { MenuItem } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pwc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnChanges {


  menuHidden = true;
  items: MenuItem[];
  credentials: Credentials;
  translateTo = this.getNextTranslation(environment.defaultLanguage);
  isAdmin = false;
  isUser = false;
  isUnAuthorized = false;
  @Input() closeMenubutton: boolean;
  searchCalendarRoute: any = '/search-calendar';
  mySearchHistoryRoute: any = '/my-search-history';
  logsAndReportRoute: any = '/logs-and-report';
	activeItem: MenuItem;

  getNextTranslation(language: string): string {
    return language == environment.defaultLanguage ? 'French' : 'English';
  }



  constructor(
    private router: Router,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private i18nService: I18nService
  ) {
    this.isUnAuthorized = this.authenticationService.getIsUnAuthorized;
    this.router = router;
    this.isAdmin = this.authenticationService.credentials
			? this.authenticationService.credentials.isAdmin
      : false;
    this.isUser = this.authenticationService.credentials
			? this.authenticationService.credentials.isUser
			: false;
  }

  ngOnInit() {
    this.credentials = this.authenticationService.credentials; 
    this.initializeMenu();
 }

 initializeMenu() {

   this.items = [];
   if (this.isUser || this.isAdmin) {
    this.items.push({ label: 'Search Calendar', routerLink: this.searchCalendarRoute });
    this.items.push({ label: 'My Search History', routerLink: this.mySearchHistoryRoute });
  }
   if (this.isAdmin) {
     this.items.push({ label: 'Logs & Reports', routerLink: this.logsAndReportRoute });
   }
  this.activeItem = this.items[this.items.findIndex((item) => item.routerLink === this.router.url)];
}

  ngOnChanges(changes: SimpleChanges) {
  }

  toggleTranslation() {
    const currentLangange = this.translateService.currentLang;
    let nextLanguage = environment.defaultLanguage;
    // if (currentLangange == environment.defaultLanguage) {
    //   nextLanguage = environment.alternateLanguage;
    // }
    this.translateService.use(nextLanguage);
    this.translateTo = this.getNextTranslation(nextLanguage);
  }

  onNavigationMenu(routeTitle: string, routeLink: string) {
    if (!routeTitle) return false;
    switch (routeTitle.toLowerCase()) {
      case 'home':
        sessionStorage.removeItem('appState');
        const appStateForHome = {
          page: 'home',
          refresh: false          
        };
        sessionStorage.setItem('appState', JSON.stringify(appStateForHome));
        this.router.navigate([routeLink]);
        break;
        
      default:
        this.router.navigate([routeLink]);
        break;
    }
  }
  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get username(): string {
    const credentials = this.authenticationService.credentials;
    return credentials ? credentials.displayName : null;
  }
}
