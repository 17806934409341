import { Injectable, Injector } from '@angular/core';
import {
    HttpClient,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { OAuthAuthenticationService } from './google.authentication.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    constructor(public authenticationService: AuthenticationService,
        private inj: Injector) {

    }


    getNewRequest(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req = req.clone({
            setHeaders: {
                Authorization: 'Bearer ' + token
            },
            url: req.url
        });
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let googleAuthenticationService = this.inj.get(OAuthAuthenticationService);
        return next.handle(request)
        .pipe(tap(
            (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {   
                //customise the response before subscribe         
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    googleAuthenticationService.logout();                    
                    /*return googleAuthenticationService.getNewOAuthToken().subscribe((data: any) => {
                        this.authenticationService.setAuthToken('Bearer ' + data.access_token);                       
                        return next.handle(this.getNewRequest(request, data.access_token));

                    })*/
                }
            }
        }));
    }


}
