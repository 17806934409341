import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Logger } from '../core/logger.service';
import { OAuthAuthenticationService } from '../core/authentication/google.authentication.service';
import { NavUtilityService } from '../shared/services/nav-utility.service';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { finalize, takeWhile } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const log = new Logger('Oauth2');

@Component({
  selector: 'pwc-oauth2',
  template: '',
  styles: ['']
})
export class Oauth2Component implements OnInit {
  authResponse: any;
  userStateResponse: any;
  isLoading = false;
  private alive = true;

  constructor(private activatedRoute: ActivatedRoute,
    private googleAuthenticationService: OAuthAuthenticationService,
    private authenticationService: AuthenticationService,
    private navUtilityService: NavUtilityService,
    private router: Router) { }

  ngOnInit() {
    let authCode: string;
    
    // subscribe to router event
    this.activatedRoute.queryParams.pipe(takeWhile(() => this.alive)).subscribe((params: Params) => {
      authCode = params['code'];
    });

    this.authenticationService.removeSessionShareInfo();
    
    this.authenticationService.setIsUnAuthorized(false);
    this.authenticationService.setIsAccessDenied(false);
    this.navUtilityService.setIsLoggedOut(false);
    this.navUtilityService.setIdleConfiguration(environment.idleConfiguration);
    this.isLoading = true;
    this.googleAuthenticationService.handleAuthCode(authCode).pipe(
      takeWhile(() => this.alive),
      finalize(() => {
        this.isLoading = false;
      })
    )
      .subscribe(
        (response: any) => {
          if (!!response) {
            const data = {
              userEmail: response.userEmail,
              displayName: response.userName,
              sessionId: response.sessionId,
              isAdmin: response.user_type === 'Master' ? true : false,
              isUser: response.user_type === 'User' ? true : false
            };
            this.navUtilityService.appUserName.next(data);
            this.navUtilityService.setSessionId(response.sessionId);
            this.authenticationService.updateCredentials(data, true);
            if (data.isAdmin || data.isUser) {
              this.router.navigate(['/search-calendar']);
            } else {
              this.router.navigate(['/access-denied']);
            }
            
          } else {
            this.authenticationService.setIsUnAuthorized(true);
            this.authenticationService.setIsAccessDenied(true);
            this.router.navigate(['/access-denied']);
          }
        },
        (error) => {
          log.info(
            'You are not authorized to use this application. Please check your credentials and login again.'
          );
          this.authenticationService.setIsUnAuthorized(true);
          this.authenticationService.setIsAccessDenied(true);
          this.router.navigate(['/access-denied']);
        }
      );
  }
}
