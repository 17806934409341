<div class="container-fluid top-banner">
    <div class="row top-menu">
        <div class="col-sm-12 col-md-7 col-lg-9 float-left logo-section-left">
            <img class="imgSize addMarginleft addMarginRight" src="./assets/img/pwc-logo.png" alt="pwc">
            <span class="appNameFont addMarginleft" translate> CustomHeader.AppName</span>
            <div class="appNameContainer">
                <div class="user-icon-container">
                    <span class="user-icon-div" (click)="toggleMenu()" (click)="clickedInside($event)">
                        <i class="fa fa-user"></i>
                    </span>
                    <ul class="user-item " *ngIf="!menuHidden && isLoggedIn">
                        <li>
                            {{'CustomHeader.Welcome' | translationPipe}}, {{displayName}}
                        </li>
                        <li>
                            <a (click)="logout($event)" class="hyperlinks" translate>Logout</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-5 col-lg-3" *ngIf="isLoggedIn">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-6 no-padding">
                    <span class="float-right " style="float: right;">{{'CustomHeader.Welcome' | translationPipe}}, {{displayName}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-6 no-padding">
                    <span class="float-right " style="float: right;">&nbsp;&nbsp;
                        <a (click)="logout($event)" class="hyperlinks c-burgandy" translate> Logout </a>
                    </span>
                    <span class="float-right " style="float: right;">&nbsp;&nbsp;
                        <a class="cursor-hand noPadding c-burgandy"
                            title="{{'CustomHeader.Help' | translationPipe}}" (click)="showSideBar('help')"
                            translate>
                            <i class="fa fa-question-circle fa-2x c-burgandy"></i>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>

</div>
<p-dialog [(visible)]="display" [modal]="true" [closable]="false" [responsive]="true" [style]="{width: '450px'}"
    [minY]="70" [baseZIndex]="10000">
    <p-header class="headerTitle">
        <label translate>CustomHeader.SessionTimeoutMessage</label>
    </p-header>
    <label translate>CustomHeader.CountDownMessage</label> <label>{{countdownMessage}}</label>
    <p-footer>
        <button translate type="button" class="btn btn-default btn-md menu-icons sdmButton" (click)="display=false">
            CustomHeader.ExtendSession</button>
        <button translate type="button" class="btn btn-default btn-md menu-icons sdmButton" (click)="logout($event)">
            CustomHeader.Logout</button>
    </p-footer>
</p-dialog>
<p-toast ></p-toast>
<p-sidebar [(visible)]="displaySlider" [fullScreen]="true">
    <div class="googleSearchCalendar-container px-3">
        <p-panel>
            <p-header>
                    <span translate class="headerTitle">CustomHeader.Help</span>
            </p-header>
            <div class="helpText p-2" *ngIf="displayContent1">
                <span translate>CustomHeader.HelpBody1</span>
                <span translate>CustomHeader.HelpBody2</span>
                <div class="helpTextHeader c-burgandy my-2" translate>CustomHeader.SearchCalendarHeader</div>
                <span translate>CustomHeader.HelpPageBogy3</span>
                <span translate>CustomHeader.HelpPageBogy4</span>
                <span class="c-burgandy helpTextItalics" translate>CustomHeader.SearchCalendarHeader</span>
                <span translate>CustomHeader.HelpPageBogy5</span>
                <table *ngIf="displayContent1" [hidden]="!displayContent1">
                    <tr>
                        <th class="c-burgandy" style="width:100px;  text-align: center">Field Name</th>
                        <th class="c-burgandy" style="text-align: center">Description</th>
                        <th class="c-burgandy" style="width:130px;  text-align: center">Example</th>
                    </tr>
                    <tr>
                        <td>
                            Select Users
                        </td>
                        <td>
                            The value entered needs to be valid cloud e-mail address to
                            perform a <label class="helpPageText">Event</label> search.
                        </td>
                        <td>
                            &nbsp;alex.m@pwc.com
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Search by Keyword(s)
                        </td>
                        <td>
                            The optional value can be any free text. If data is entered in this field, the system
                            will refine the search results based events with the matching text within google cloud.
                        </td>
                        <td>
                            &nbsp;PwC
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Date Range
                        </td>
                        <td>
                            The value entered should be a valid Date. If data is entered in this field, the system
                            will refine the search results within provided date range .
                        </td>
                        <td>
                            &nbsp;12/09/2014
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Others
                        </td>
                        <td>
                            If <label class="helpTextItalics c-burgandy">Show Deleted Events</label> is chosen, the
                            system
                            will include all the deleted events in the search results returned by Google Cloud. <br>
                            If <label class="helpTextItalics c-burgandy">Only Single Event</label> is chosen, the system
                            will
                            include only one event from the multiple recurring events.<br>
                            If <label class="helpTextItalics c-burgandy">Include Attachments</label> is chosen, the
                            system
                            will include attachments present in each events in the search results returned by Google
                            Cloud. <br>
                            If <label class="helpTextItalics c-burgandy">Separate ICS Files</label> is chosen, the
                            system will
                            create separate ICS files for each event.
                        </td>
                        <td>
                            <div>
                                <input type="checkbox" checked disabled>
                                <label translate>SearchCalendar.ShowDeletedEvents</label>
                            </div>
                            <div>
                                <input type="checkbox" disabled>
                                <label translate>SearchCalendar.OnlySingleEvent</label>
                            </div>
                            <div>
                                <input type="checkbox" checked disabled>
                                <label translate>SearchCalendar.IncludeAttachments</label>
                            </div>
                            <div>
                                <input type="checkbox" checked disabled>
                                <label translate>SearchCalendar.SeparateICSFiles</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Search button
                        </td>
                        <td>
                            Clicking on this button will execute the search based on the specified criteria and will
                            direct you to the search results page.
                        </td>
                        <td style=" text-align: center">
                            <button pButton type="submit" class="btn btn-primary btn-md sdmButton"
                                label="{{'Common.Search' | translationPipe}}"></button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Reset button
                        </td>
                        <td>
                            Clicking on this button will clear the data you have entered.
                        </td>
                        <td style=" text-align: center">
                            <button pButton type="button" class="btn btn-primary btn-md sdmButton"
                                label="{{'Common.Reset' | translationPipe}}"></button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Help button
                        </td>
                        <td>
                            Clicking on this button will direct you to the help pages.
                        </td>
                        <td style=" text-align: center">
                            <a class="noPadding c-burgandy" title="{{'CustomHeader.FAQLabel' | translationPipe}}"
                                translate>
                                <i class="fa fa-question-circle fa-2x c-burgandy"></i>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Log out link
                        </td>
                        <td>
                            Clicking on this link will log you off of the system.
                        </td>
                        <td style=" text-align: center">
                            <a class="hyperlinks" translate>Logout</a>
                        </td>
                    </tr>
                </table>
            </div>
           
            <div class="helpText p-2" *ngIf="displayContent2">
                <span translate>CustomHeader.HelpBody1</span>
                <span translate>CustomHeader.HelpBody2</span>
                <div class="helpTextHeader c-burgandy my-2" translate>CustomHeader.MyDownloadHistory</div>
                <span translate>CustomHeader.HelpPageBogy6</span>
                <span class="c-burgandy helpTextItalics" translate>CustomHeader.SearchCalendarHeader</span>
                <span translate>CustomHeader.HelpPageBogy8</span>
                <span translate>CustomHeader.HelpPageBogy7</span>
                <span class="c-burgandy helpTextItalics" translate>CustomHeader.MyDownloadHistory</span>
                <span translate>CustomHeader.HelpPageBogy5</span>
                <table *ngIf="displayContent2" [hidden]="!displayContent2">
                    <tr>
                        <th class="c-burgandy" style="width:200px;  text-align: center">Field Name</th>
                        <th class="c-burgandy" style="text-align: center">Description</th>
                        <th class="c-burgandy" style="width:250px;  text-align: center">Example</th>
                    </tr>
                    <tr>
                        <td>
                            E-mail
                        </td>
                        <td>
                            This field displays the individual E-mail for which user is perfoming search.
                        </td>
                        <td>
                            &nbsp;alex.m@pwc.com
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Search Time
                        </td>
                        <td>
                            This field displays the time at which search has been performed.
                        </td>
                        <td>
                            &nbsp;25/06/2019 15:53:42 
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Keyword
                        </td>
                        <td>
                            This field displays the Keyword used for search against cloud.
                        </td>
                        <td>
                            &nbsp;PwC
                        </td>
                    </tr>
                    <tr>
                        <td>
                            From Date
                        </td>
                        <td>
                            This field displays the From Date of the search criteria.
                        </td>
                        <td>
                            &nbsp;12/09/2014
                        </td>
                    </tr>
                    <tr>
                        <td>
                            To Date
                        </td>
                        <td>
                            This field displays the To Date of the search criteria.
                        </td>
                        <td>
                            &nbsp;12/12/2014
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Total Matching Events 
                        </td>
                        <td>
                            This field displays the total number of events found for the provided search criteria.
                        </td>
                        <td >
                           &nbsp;34
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Total Calendars
                        </td>
                        <td>
                            This field displays the total number of calendars available for person.
                        </td>
                        <td>
                            &nbsp;2
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Shared Calendars 
                        </td>
                        <td>
                            This field displays the email ID(s) of the person sharing the calendar of person.
                        </td>
                        <td >
                           &nbsp;dona.woodsuat@dev.pwc.com
                        </td>
                    </tr>
                    <tr>
                            <td>
                                Attachment Summary
                            </td>
                            <td>
                                <div><strong>Total Attachment(s) : </strong>This field displays the count of total attachment(s) found in the search.</div>
                                <div><strong>Downloaded Attachment(s) : </strong>This field displays the count about successfully downloaded attachment(s).</div>
                                <div><strong>Failed Attactment(s) : </strong> This field displays failed attachment count and error log.</div> </td>
                            <td>
                                <div><strong>Total Attachment(s) : </strong>2</div>
                                <div><strong>Downloaded Attachment(s) : </strong> 2</div>
                                <div><strong>Failed Attactment(s) : </strong></div>
                                <div>Total Error Files : 1</div>
                                <div>Permission Error Files : 1</div>
                            </td>
                        </tr>
                    <tr>
                        <td>
                            Status
                        </td>
                        <td>
                            This field displays the Status of the current search operation against google cloud.
                        </td>
                        <td >
                           &nbsp;Success
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Download
                        </td>
                        <td>
                            Clicking on this button will Download the zip file to user's local machine.
                        </td>
                        <td style=" text-align: center">
                            <button pButton type="submit" class="buttonInGrid" type="button" pButton icon="fa fa-download"
                            title="{{'FolderContainer.DownloadLabel' | translationPipe}}"></button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Refresh
                        </td>
                        <td>
                            Clicking on this button will refresh the 'My Search History' grid.
                        </td>
                        <td style=" text-align: center">
                            <button pButton type="submit" class="btn btn-primary btn-md sdmButton"
                            label="{{'Common.Refresh' | translationPipe}}"></button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Export To Google Sheet
                        </td>
                        <td>
                            Clicking on this button will export 'My Search History' data to Google sheet.
                        </td>
                        <td style=" text-align: center">
                            <button pButton type="submit" class="btn btn-primary btn-md sdmButton"
                            label="{{'LogsAndReports.ExportIcon' | translationPipe}}"></button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Help button
                        </td>
                        <td>
                            Clicking on this button will direct you to the help pages.
                        </td>
                        <td style=" text-align: center">
                            <a class="noPadding c-burgandy" title="{{'CustomHeader.FAQLabel' | translationPipe}}"
                                translate>
                                <i class="fa fa-question-circle fa-2x c-burgandy"></i>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Log out link
                        </td>
                        <td>
                            Clicking on this link will log you off of the system.
                        </td>
                        <td style=" text-align: center">
                            <a class="hyperlinks" translate>Logout</a>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="helpText p-2" *ngIf="displayContent3">
                <span translate>CustomHeader.HelpBody1</span>
                <span translate>CustomHeader.HelpBody2</span>
                <div class="helpTextHeader c-burgandy my-2" translate>CustomHeader.LogsAndReportsHeader</div>
                <span translate>CustomHeader.HelpBodyLogs</span>
                <span class="c-burgandy helpTextItalics" translate>CustomHeader.LogsAndReportsHeader</span>
                <span translate>CustomHeader.HelpPageBogy5</span>
                <br/>
                <table *ngIf="displayContent3" [hidden]="!displayContent3">
                    <tr>
                        <th class="c-burgandy" style="width:200px;  text-align: center">Field Name</th>
                        <th class="c-burgandy" style="text-align: center">Description</th>
                        <th class="c-burgandy" style="width:230px;  text-align: center">Example</th>
                    </tr>
                    <tr>
                        <td>
                        From Date
                        </td>
                        <td>
                            This field displays the From Date of the search criteria.
                        </td>
                        <td>
                            &nbsp;07/10/2019
                        </td>
                    </tr>
                    <tr>
                        <td>
                            To Date
                        </td>
                        <td>
                            This field displays the To Date of the search criteria.
                        </td>
                        <td>
                            &nbsp;07/17/2019
                        </td>
                    </tr>
                    <tr>
                        <td>
                            User ID
                        </td>
                        <td>
                            This field displays the email ID of the logged in OGC user.
                        </td>
                        <td>
                            &nbsp;alex.m@pwc.com
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Country
                        </td>
                        <td>
                            This field displays the Country matching with OGC user country code.
                        </td>
                        <td>
                            &nbsp;US
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Search Time
                        </td>
                        <td>
                            This field displays the time at which search performed against google Cloud.
                        </td>
                        <td>
                            &nbsp;25/06/2019 15:37:50
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Search Criteria
                        </td>
                        <td>
                            <div><strong>User Email : </strong>This field displays the details about e-mail address of against which search has been done.</div>
                            <div><strong>Keyword : </strong>This field displays the Keyword used for search against cloud.</div>
                            <div><strong>From Date : </strong>This field displays the From Date of the search criteria.</div>
                            <div><strong>To Date : </strong>This field displays the To Date of the search criteria.</div>
                            <div><strong>Deleted Events : </strong>This field indicates whether Show Deleted Invitation is chosen while performing search against Google</div>
                            <div><strong>Single Events : </strong>This field indicates whether Only Single Invitation is chosen while performing search against Google</div>
                            <div><strong>Include Attachments : </strong>This field indicates whether Include Attachments is chosen while performing search against Google</div>
                            <div><strong>Separate ICS Files : </strong>This field indicates whether Separate ICS Files is chosen while performing search against Google</div>
                        </td>
                        <td>
                            <div><strong>User Email : </strong>dona.woodsuat@dev.pwc.com</div>
                            <div><strong>Keyword : </strong> PwC</div>
                            <div><strong>From Date : </strong>12/09/2014</div>
                            <div><strong>To Date : </strong>12/12/2014</div>
                            <div><strong>Deleted Events : </strong>Yes</div>
                            <div><strong>Single Events : </strong>No</div>
                            <div><strong>Include Attachments : </strong>Yes</div>
                            <div><strong>Separate ICS Files : </strong>Yes</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            No. of Events
                        </td>
                        <td>
                            This field displays the total number of matching events found in search.
                        </td>
                        <td>
                            34
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Status
                        </td>
                        <td>
                            This field displays the status of the search.
                        </td>
                        <td>
                            Success
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Log In Time
                        </td>
                        <td>
                            This field displays the Login time of OGC User.
                        </td>
                        <td>
                            &nbsp;25/06/2019 15:07:50
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Log Out Time
                        </td>
                        <td>
                            This field displays the Logout time of OGC User.
                        </td>
                        <td>
                            &nbsp;25/06/2019 15:47:10
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Search button
                        </td>
                        <td>
                            Clicking on this button will generate the report based on the provided search criteria.
                        </td>
                        <td style=" text-align: center">
                            <button pButton type="submit" class="btn btn-primary btn-md sdmButton"
                                label="{{'Common.Search' | translationPipe}}"></button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Reset button
                        </td>
                        <td>
                            Clicking on this button will Reset all the search fields.
                        </td>
                        <td style=" text-align: center">
                            <button pButton type="submit" class="btn btn-primary btn-md sdmButton"
                                label="{{'Common.Reset' | translationPipe}}"></button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Export To Google Sheet
                        </td>
                        <td>
                            Clicking on this button will export 'Logs & Reports' data to Google sheet.
                        </td>
                        <td style=" text-align: center">
                            <button pButton type="submit" class="btn btn-primary btn-md sdmButton"
                                label="{{'LogsAndReports.ExportIcon' | translationPipe}}"></button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Help button
                        </td>
                        <td>
                            Clicking on this button will direct you to the help pages.
                        </td>
                        <td style=" text-align: center">
                            <a class="noPadding c-burgandy" title="{{'CustomHeader.FAQLabel' | translationPipe}}"
                                translate>
                                <i class="fa fa-question-circle fa-2x c-burgandy"></i>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Log out link
                        </td>
                        <td>
                            Clicking on this link will log you off of the system.
                        </td>
                        <td style=" text-align: center">
                            <a class="hyperlinks" translate>Logout</a>
                        </td>
                    </tr>
                </table>
            </div>
        </p-panel>
    </div>
</p-sidebar>