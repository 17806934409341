import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router,} from '@angular/router';

import { Logger } from '../logger.service';
import { OAuthAuthenticationService } from './google.authentication.service';
import { AuthenticationService } from './authentication.service';
const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(private router: Router,
    private googleAuthenticationService: OAuthAuthenticationService,
    private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.authenticationService.isAuthenticated() || this.authenticationService.getIsAccessDenied) {
      return true;
    } else {
      log.debug('Not authenticated, redirecting...');
      // this.googleAuthenticationService.setShareInfoFromUrl(this.getParameterByName('share'));
      this.googleAuthenticationService.logout();
      return false;
    }
  }
}
