import { Component, OnInit, HostListener, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService, Credentials } from '../../authentication/authentication.service';
import { I18nService } from '../../i18n.service';
import { NavUtilityService } from '../../../shared/services/nav-utility.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import { Table } from 'primeng/table';
import { SharedApiUtilityService } from 'app/shared/services/shared-api-utility.service';

@Component({
  selector: 'pwc-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})
export class CustomHeaderComponent implements OnInit {
  @Output() closeMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  menuHidden = true;
  userEmail = '';
  displayName: string;
  credentials: Credentials;
  isLoggedIn = true;
  display = false;
  countdownMessage = '';
  displaySlider = false;
  displayContent1 = false;
  displayContent2 = false;
  displayContent3 = false;
  // customHeaderGrowlMessage: Message[] = [];
  helpGridColumn: any[];
  helpGridColumnData: any[] = [];
  @ViewChild('grid') grid: Table;
  rowsPerPageOptions: number[] = [20, 50, 100, 200];
  selectedrowsPerPageOption: number = this.rowsPerPageOptions[0];
  first = 0;
  sessionId = '';
  private alive = true;

  constructor(private router: Router,
    private navUtilityService: NavUtilityService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private messageService: MessageService,
    private ref: ChangeDetectorRef,
    private sharedApiUtilityService: SharedApiUtilityService) {
    this.credentials = this.authenticationService.credentials;
    this.userEmail = this.authenticationService.credentials ?
      this.authenticationService.credentials.userEmail : null;
    this.displayName = this.authenticationService.credentials ?
      this.authenticationService.credentials.displayName : null;
    this.sessionId = this.authenticationService.credentials
      ? this.authenticationService.credentials.sessionId
      : null;
    this.showDialog();
  }

  showDialog() {
    this.navUtilityService.timoutWarningData.subscribe((countdown) => {
      this.display = countdown > 0 ? true : false;
      if (!!countdown && countdown > 0) {
        const minutes = Math.floor(countdown / 60);
        const seconds = countdown - (minutes * 60);
        let message = '';
        if (!!minutes && minutes > 0) {
          message = minutes + this.translateService.instant('CustomHeader.Minutes');
        }
        if (!!seconds && seconds > 0) {
          message += seconds + this.translateService.instant('CustomHeader.Seconds');
        }
        this.countdownMessage = message;
      }

    })
  }
  ngOnInit() {
    this.helpGridColumn = [
      {
        field: 'description', header: 'Description', isHidden: false
      },
      {
        field: 'example', header: 'Example', isHidden: false
      }];

    this.navUtilityService.appUserName.subscribe((credentials) => {
      if (!!credentials && !!credentials.userEmail) {
        this.userEmail = credentials.userEmail;
      }
    });
    this.navUtilityService.searchInitiated.pipe(takeWhile(() => this.alive)).subscribe((data) => {
      if (!!this.navUtilityService.getShowSearchInitiatedMessage) {
        this.messageService.clear();
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('Common.Success'),
          detail: this.translateService.instant('CustomHeader.SearchInitiatedMessage')
        });
        this.navUtilityService.setShowSearchInitiatedMessage(false);
      }
    });
    this.navUtilityService.countDownMessageCompleted.pipe(takeWhile(() => this.alive)).subscribe((data) => {
      if (!!data) {
        this.display = false;
        this.isLoggedIn = false;
      }
    });
    this.navUtilityService.menuButtonClicked.pipe(takeWhile(() => this.alive)).subscribe((data) => {
      if (!!data) {
        switch (data.title) {
          case 'Search Calendar':
            this.displayContent1 = true;
            this.displayContent2 = false;
            this.displayContent3 = false;
            break;
          case 'My Search History':
            this.displayContent2 = true;
            this.displayContent1 = false;
            this.displayContent3 = false;
            break;
          case 'Logs and Reports':
            this.displayContent3 = true;
            this.displayContent1 = false;
            this.displayContent2 = false;
            break;

          default:

            break;
        }

      }

    });
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  logout(event: any) {
    let status = '';
    this.sharedApiUtilityService.logOut(this.sessionId)
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: any) => {
        status = data.status
        if (!!status && status.toLowerCase() === 'success') {
          this.display = false;
          this.isLoggedIn = false;
          this.authenticationService.updateCredentials(null);
          this.navUtilityService.setIsLoggedOut(true);
          this.navUtilityService.isLoginSubject.next(false);
          this.navUtilityService.appLogOutSessionTimeout.next(false);
          this.router.navigate(['/logout']);
          event.preventDefault();
        } else {
          this.display = false;
          this.isLoggedIn = false;
          this.navUtilityService.appLogOutSessionTimeout.next(false);
          this.router.navigate(['/logout']);
          this.navUtilityService.isLoginSubject.next(false);
          this.authenticationService.updateCredentials(null);
          this.navUtilityService.setIsLoggedOut(true);
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('Common.Error'),
            detail: data.description
          });
        }
      });
  }


  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get username(): string {
    const credentials = this.authenticationService.credentials;
    return credentials ? credentials.displayName : null;
  }

  // On click outside of User icon hide the Repo Div at Mobile Devices
  clickedInside($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();  // <- that will stop propagation on lower layers
  }
  @HostListener('document:click', ['$event']) clickedOutside($event: Event) {
    if (this.menuHidden === false) {
      this.menuHidden = true;
    }
  }

  showSideBar(value: any) {
    this.displaySlider = true;
  }
}
