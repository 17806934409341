import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LogsAndReportService {

  private logsAndReportsListRoute = environment.serverUrl + '/logsReport';
  private exportLogsAndReportRoute = environment.serverUrl + '/exportLogsAndReport';
  constructor(private http: HttpClient) { }


  getReportList(paginationReq: boolean, offsetValue: any, limit: any,
    createdFrom: string, createdTo: string, userEmail: string, sessionId: string): Observable<any >{
    const formData: FormData = new FormData();
    if (!!createdFrom && !!createdTo) {
      formData.append('from_date', createdFrom);
      formData.append('to_date', createdTo);
    }
    formData.append('user_email', userEmail);
    formData.append('session_id', sessionId);
    return this.http.post(this.logsAndReportsListRoute, formData)
      .pipe(map((res: any) => res),
        catchError(error => throwError(error.message || "server error.")));
  }


  // tslint:disable-next-line:max-line-length
  exportData(currentTime: string, userEmail: string, sessionId: string, fromDateUserTime: string, toDateUserTime: string): Observable<any > {
    const formData: FormData = new FormData();
    formData.append('user_email', userEmail);
    formData.append('session_id', sessionId);
    formData.append('time_zone', currentTime);
    formData.append('from_date', fromDateUserTime);
    formData.append('to_date', toDateUserTime);
    return this.http.post(this.exportLogsAndReportRoute, formData , {responseType: 'text', observe: 'response' as 'body'})
      .pipe(map((res: any) => res),
        catchError(error => throwError(error.message || "server error.")));
  }
}
