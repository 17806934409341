
import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class SharedApiUtilityService {
  
  private usersGroupsQueryListRoute = environment.serverUrl + '/lookupuser' + '?query=';
  private logoutRoute = environment.serverUrl + '/logout';


  constructor(private http: HttpClient) { }


  getUsersGroupsList(query: string): Observable<any > {
  return this.http.get(this.usersGroupsQueryListRoute + query)
     .pipe(map((res: any) => res),
     catchError(error => throwError(error.message || "server error.")));
  }

  logOut(sessionId: string): Observable<any > {
    const formData: FormData = new FormData();
    formData.append('session_id', sessionId);
    return this.http.post(this.logoutRoute, formData)
      .pipe(map((res: any) => res),
        catchError(error => throwError(error.message || "server error.")));
  }


}
