import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavUtilityService } from '../shared/services/nav-utility.service';
import { Observable } from 'rxjs';

@Injectable()
export class HomeService {

  constructor(private http: HttpClient, private navUtilityService: NavUtilityService) { }


}
