import { Component, OnInit, OnDestroy } from '@angular/core';
import { OAuthAuthenticationService } from 'app/core/authentication/google.authentication.service';
import { NavUtilityService } from '../shared/services/nav-utility.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'pwc-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {
  displaySessionTimeOutMessage = false
  isLoading = false;
  private alive = true;

  constructor(private authenticationService: OAuthAuthenticationService,
    private navUtilityService: NavUtilityService) { }

  ngOnInit() {
    this.navUtilityService.appLogOutSessionTimeout.pipe(takeWhile(() => this.alive)).subscribe((data) => {
      if (!!data) {
        this.displaySessionTimeOutMessage = true;
      }
    });
  }

  login() {
    this.isLoading = true;
    this.authenticationService.logout();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.alive = false;
  }

}
