<div class="googleSearchCalendar-container">
  <p-panel class="no-header">
    <form [formGroup]="searchEventForm" (ngSubmit)="searchEvent(searchEventForm.value, 'false')">
      <div class="m-2">
        <div class="row">
          <div class="col-md-2">
            <label for="reportType" translate>SearchCalendar.SelectUser
              <span class="isRequired"> *</span>
            </label>
          </div>
          <div class="col-md-10">
            <p-autoComplete field="value" class="autocomplete removeInputWidth p-fluid" formControlName="userEmail"
              id="userEmails" name="userEmails" placeholder="{{'Common.AutoCompletePlaceholder' | translationPipe}}"
              [suggestions]="filteredOwnersMultiple" (completeMethod)="filterOwnerMultiple($event)"
              (onSelect)="addedUserValue($event)" (onUnselect)="removedUserValue($event)" [minLength]="3" [delay]="300"
              [multiple]="true" ngDefaultControl></p-autoComplete>
            <div
              *ngIf="searchEventForm.controls['userEmail'].errors && (searchEventForm.controls['userEmail'].dirty || searchEventForm.controls['userEmail'].touched)"
              class="alert alert-danger">
              <div [hidden]="!searchEventForm.controls['userEmail'].errors.required" translate>
                SearchCalendar.RoleNameValReq
              </div>
            </div>
          </div>
        </div>
        <div style="height:7px"></div>
        <div class="row">
          <div class="col-md-2">
            <label for="reportType" translate>SearchCalendar.EnterKeyword
            </label>
          </div>
          <div class="col-md-10">
            <textarea pInputTextarea rows="1" class="form-control" maxlength="500" autoResize="autoResize"
              formControlName="keyword" name="keyword "
              placeholder="{{'SearchCalendar.SearchByKeywordPlaceholder' | translationPipe}}"></textarea>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-md-6 p-0 float-left">
            <div class="row">
              <div class="col-md-4">
                <label for="createdDateFrom" translate>SearchCalendar.FromDate
                </label>
              </div>
              <div class="col-md-8">
                <p-calendar dateFormat="mm/dd/yy" formControlName="fromDate" monthNavigator="true" yearNavigator="true"
                  class=" calender-width" placeholder="{{'SearchCalendar.DatePlaceholderFrom' | translationPipe}}"
                  yearRange="{{minYear}}:{{maxYear}}" [showIcon]="true" showButtonBar="true"
                  [maxDate]="searchEventForm.controls['toDate'].value" class="upasCalendarControl"></p-calendar>
              </div>
            </div>
          </div>
          <div class="col-md-6 float-right p-0">
            <div class="row">
              <div class="col-md-4">
                <label for="createdDateTo" translate>SearchCalendar.ToDate
                </label>
              </div>
              <div class="col-md-8">
                <p-calendar dateFormat="mm/dd/yy" formControlName="toDate" monthNavigator="true" yearNavigator="true"
                  class="calender-width " placeholder="{{'SearchCalendar.DatePlaceholderTo' | translationPipe}}"
                  yearRange="{{minYear}}:{{maxYear}}" [showIcon]="true" showButtonBar="true"
                  [minDate]="searchEventForm.controls['fromDate'].value" class="upasCalendarControl">
                </p-calendar>
              </div>
            </div>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-2">
            <div>
              <p-checkbox formControlName="showDeletedEvents" binary="true"></p-checkbox>&nbsp;
              <label translate>SearchCalendar.ShowDeletedEvents</label>
            </div>
          </div>
          <div class="col-2">
            <div>
              <p-checkbox formControlName="onlySingleEvents" binary="true"></p-checkbox>&nbsp;
              <label translate>SearchCalendar.OnlySingleEvent</label>
            </div>
          </div>
          <div class="col-2">
            <div>
              <p-checkbox formControlName="includeAttachments" binary="true"></p-checkbox>&nbsp;
              <label translate>SearchCalendar.IncludeAttachments</label>
            </div>
          </div>
          <div class="col-2">
            <div>
              <p-checkbox formControlName="separateICSFiles" binary="true"></p-checkbox>&nbsp;
              <label translate>SearchCalendar.SeparateICSFiles</label>
            </div>
          </div>
          <div class="col-2">
          </div>
          <div class="col-2">
          </div>
        </div>
        <br />
        <div class="row my-2">
          <div class="col-md-8 float-left">
            <div class="form-group">
              <button pButton type="submit" class="btn btn-primary btn-md sdmButton"
                label="{{'Common.Search' | translationPipe}}" [disabled]="!searchEventForm.valid"></button>
              <button pButton type="button" class="btn btn-primary btn-md sdmButton"
                label="{{'Common.Reset' | translationPipe}}"
                (click)="reset()"></button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="isSearchedInFourteenDays" class="m-2 SearchWithinFourteenDaysPanel">
      <div class="row p-3">
        <div class="col-md-12  addBorder py-3">
          <span translate>SearchCalendar.SearchWithinFourteenDaysPart1</span><span
            translate>SearchCalendar.SearchWithinFourteenDaysPart2</span>

          <p-table [hidden]="noEventFlag" [value]="searchList" [rows]="selectedrowsPerPageOption"
            [columns]="searchListColumn" [rowsPerPageOptions]="rowsPerPageOptions" [pageLinks]="4"
            [reorderableColumns]="false" [responsive]="true" (onSort)="retainSort($event)" [paginator]="true"
            [resizableColumns]="true" #grid (onPage)="pageChange($event)" [(first)]="first" styleClass="">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field" [hidden]="col.isHidden"
                  [ngStyle]="col.style" [ngClass]="col.class">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th [ngStyle]="{'width': '4%'}"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData" [ngClass]="rowData.isRowHighlight ? 'file-selected-row' : ''">
                  <td *ngFor="let col of columns" [hidden]="col.isHidden">
                      <div *ngIf="!col.isTruncate && !col.isFailedAttachmentCol && !col.isCustomDate && !col.isNumeric">
                        <span>{{rowData[col.field]}}</span>
                      </div>
                      <div *ngIf="!!col.isTruncate && !col.isCustomDate && !col.isNumeric" class="ellipsis cursor-hand">
                        <span (click)="completePopupData(col,rowData[col.field])">{{rowData[col.field]}}</span>
                      </div>
                      <div
                        *ngIf="!col.isTruncate && !!col.isAttachmentSummaryCol && !col.isCustomDate && !col.isNumeric">
                        <label *ngIf="rowData.attachmentSummary.totalAttachments"><strong translate>Total Attachment(s) : 
                        </strong><span>{{rowData.attachmentSummary.totalAttachments}}</span>&nbsp;&nbsp;</label>
                        <label *ngIf="rowData.attachmentSummary.downloadedAttachment"><strong translate>Downloaded Attachment(s) : 
                        </strong><span>{{rowData.attachmentSummary.downloadedAttachment}}</span>&nbsp;&nbsp;</label>
                        <label *ngIf="rowData.attachmentSummary.totalErrorFiles"><strong translate>Failed Attachment(s) : 
                        </strong>&nbsp;&nbsp;</label>
                        <label *ngIf="rowData.attachmentSummary.totalErrorFiles">Total Error Files : 
                        <span>{{rowData.attachmentSummary.totalErrorFiles}}</span>&nbsp;&nbsp;</label>
                        <label *ngIf="rowData.attachmentSummary.permissionErrorFiles">Permission Error Files : 
                        <span>{{rowData.attachmentSummary.permissionErrorFiles}}</span>&nbsp;&nbsp;</label>
                        <button *ngIf="rowData.isFailedAttactments" class="buttonInGrid" type="button" pButton
                          icon="fa fa-download" title="{{'MySearchHistory.DownloadErrorLabel' | translationPipe}}"
                          (click)="downloadErrorLog(rowData)"></button>
                      </div>
                      <div *ngIf="!!col.isDate && !!col.isCustomDate && !col.isNumeric">
                        <span>{{rowData[col.field] | date:'MM/dd/y'}}</span>
                      </div>
                      <div *ngIf="!!col.isNumeric" class="float-right">
                        <span>{{rowData[col.field]}}</span>
                      </div>
                    </td>
                    <td>
                      <button *ngIf="rowData.isSearchSuccess" class="buttonInGrid" type="button" pButton icon="fa fa-download"
                        title="{{'Common.Download' | translationPipe}}"
                        (click)="downloadZipFile(rowData)"></button>
                    </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <th colspan="5">
                <div class="text-center emptyMsg" translate>
                  Common.NoRecordsFound
                </div>
              </th>
            </ng-template>
          </p-table>
          <div class="form-group mt-3">
            <button pButton type="submit" class="btn btn-primary btn-md sdmButton" label="Download Cloud Copy" (click) = "searchEvent(searchInput, 'true')"></button>
          </div>
        </div>
      </div>

    </div>
  </p-panel>

  <div class="individual-loader-container" *ngIf="isLoading">
    <div class="individual-loader"></div>
    <div class="individual-overlay">
    </div>
  </div>
</div>

<p-toast ></p-toast>