import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';


export interface Credentials {
  // Customize received credentials here
  userEmail: string;
  displayName: string;  
  userProfiles?: string[];
  isAdmin: boolean;
  isUser: boolean;
  sessionId: string;
}

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

const credentialsKey = 'credentials';
const repoKey = 'repo';
const shareKey = 'share';
const tokenKey = 'token';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {

  private _credentials: Credentials;
  private isUnAuthorized:boolean=false;
  private userKey: string;
  private isAccessDenied:boolean=false;

  constructor() {
    this._credentials =
      JSON.parse(sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey));
  }

  /**
   * Authenticates the user.
   * @param {LoginContext} context The login parameters.
   * @return {Observable<Credentials>} The user credentials.
   */
  login(context: LoginContext): Observable<Credentials> {
    // Replace by proper authentication call
    const data = {
      userEmail: context.username,
      displayName: context.username,
      token: '123456',
      isAdmin: true,
      isUser: false,
      sessionId: ''
    };
    this.setCredentials(data, context.remember);
    return of(data);
  }

  /**
   * Logs out the user and clear credentials.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.setCredentials();
    return of(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return {Credentials} The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param {Credentials=} credentials The user credentials.
   * @param {boolean=} remember True to remember credentials across sessions.
   */
  private setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

  public updateCredentials(credentials?: Credentials, remember?: boolean) {
    this.setCredentials(null);
    this.setCredentials(credentials);
  }

  private sharedRepoId: string;
  public setSharedRepoId(repoId: string) {

    this.sharedRepoId = repoId;
  }

  get getSharedRepoId(): string {
    return this.sharedRepoId;
  }

  get getSessionRepoId(): string {
    return sessionStorage.getItem(repoKey);
  }
  get getSessionShareInfo(): string {
    return sessionStorage.getItem(shareKey);
  }

  public removeSessionShareInfo() {
    sessionStorage.removeItem(shareKey);
  }
  public removeSessionRepoId() {
    sessionStorage.removeItem(repoKey);
  }

  public setAuthToken(authtoken: string) {
    sessionStorage.setItem(tokenKey, authtoken);
  }

  get getAuthToken(): string {
    return sessionStorage.getItem(tokenKey);
  }
  setIsUnAuthorized(isUnAuthorized: boolean) {
    this.isUnAuthorized = isUnAuthorized;
  }

  get getIsUnAuthorized(): boolean {
    return this.isUnAuthorized;
  }
  setUserKey(userKey: string) {
    this.userKey = userKey;

  }
  get getUserKey(): string {
    return this.userKey
  }

  public setIsAccessDenied(isAccessDenied: boolean) {
    this.isAccessDenied=isAccessDenied;
  }

  get getIsAccessDenied(): boolean {
    return this.isAccessDenied;
  }
}
