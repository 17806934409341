import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { Oauth2RoutingModule } from './oauth2-routing.module';
import { Oauth2Component } from './oauth2.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    Oauth2RoutingModule
  ],
  declarations: [
    Oauth2Component
  ]
})
export class Oauth2Module { }
